import "./exportModal.css";

import Select from "react-select";
import React, { useState, useRef, useEffect, useCallback } from 'react';

import { getProgramUrl } from "Constants/ApiUrls";
import { exportToExcel } from "Utils/ExcelExport";
import CloseIcon from "../../../Assests/Icons/CloseIcon.svg"
import { getProgramTitles } from "Utils/ApiHandler/ProgramApi";
import { getCurrentDateTimeFormatted } from "Utils/DateTimeUtils";
import { exportDatatableRecord } from "Utils/ApiHandler/ExportDatatableApi";
import DownloadButton from "Components/Buttons/DownloadButton/downloadButton";
import { CheckboxOption, commonStyles, formatOptionLabel, noOptionsMessage } from "Components/FilterHeader/GenericDropdown";

/**
 * ExportModal is a React component that renders a modal for exporting data to Excel.
 * It allows users to select specific columns and filter data based on program selection.
 *
 * Props:
 * - title (string): The title of the data being exported.
 * - filter (array): An array of filter objects to be applied on the data.
 * - handleClose (function): Function to handle closing the modal.
 * - exportColumns (array): An array of column objects available for export.
 *
 * State:
 * - inputs (string): User input for searching programs.
 * - isTyping (boolean): Tracks if the user is typing in the search input.
 * - isClosing (boolean): Tracks if the modal is in the process of closing.
 * - exportFailed (boolean): Indicates if the export operation failed.
 * - programOptions (array): Options for program selection in dropdown.
 * - searchOptions (boolean): Determines if search options should be displayed.
 * - selectedColumns (array): Columns selected by the user for export.
 * - selectedProgram (string): Program selected by the user.
 * - downloadMessage (boolean): Displays a message while the file is downloading.
 *
 * Effects:
 * - Adds event listeners for closing modal on outside click or 'Escape' key press.
 * - Initializes selectedColumns based on compulsory columns.
 * - Debounces input changes for fetching program titles.
 *
 * Functions:
 * - handleCheckboxChange: Toggles column selection for export.
 * - getSelectedOrganization: Retrieves selected organization IDs from filters.
 * - handleCloseModal: Initiates modal closing with animation.
 * - fetchProgramTitles: Fetches program titles based on user input and filters.
 * - generateFiltersKey: Generates a key for filtering data including selected program.
 * - fetchData: Fetches and exports data based on selected columns and filters.
 * - CloseButton: A component for rendering the close button.
 * - filterAndMapColumns: Filters and maps columns for rendering with checkboxes.
*/
const ExportModal = ({
    title,
    filter,
    handleClose,
    exportColumns,
}) => {

    const exportModalRef = useRef(null);

    const [inputs, setInputs] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [exportFailed, setExportFailed] = useState(false);
    const [programOptions, setProgramOptions] = useState([]);
    const [searchOptions, setSearchOptions] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState("");
    const [downloadMessage, setDownloadMessage] = useState(false);

    const CloseButton = () => <img src={CloseIcon} onClick={handleCloseModal} alt="Close" />;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (exportModalRef.current && !exportModalRef.current.contains(event.target)) {
                handleCloseModal();
            }
        };
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                handleCloseModal();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscKey);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscKey);
        };
    }, [handleClose]);

    /**
     * Handles setting initial state of selected columns based on export columns.
     * Only selects columns that are compulsory.
     * @param {Array<Column>} exportColumns - Array of columns to be exported.
     * @returns {void}
    */
    useEffect(() => {
        const initialSelectedColumns = exportColumns.filter(column => column.isCompulsory).map(column => column.key);
        setSelectedColumns(initialSelectedColumns);
    }, [exportColumns]);

    /**
     * Fetches program titles based on the current input value with a debounce delay.
     * This effect runs whenever the `inputs` state changes.
     * Clears the debounce timer on cleanup to prevent memory leaks.
    */
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (inputs) fetchProgramTitles(inputs);
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [inputs]);

    /**
     * Toggles the selection state of a given column for export.
     * If the column is already selected, it will be deselected, and vice versa.
     * 
     * @param {string} column - The key of the column to toggle selection for.
    */
    const handleCheckboxChange = useCallback((column) => {
        setSelectedColumns(prevColumns =>
            prevColumns.includes(column) ? prevColumns.filter((col) => col !== column) : [...prevColumns, column]
        );
    }, []);

    /**
     * Returns the selected organization(s) from the filter.
     * If there is no filter by organization, returns an empty array.
     * @returns {string[]} - Array of selected organization Ids.
    */
    const getSelectedOrganization = useCallback(() => {
        const organizationFilter = filter.find(f => f.name === 'organizationIds');
        return organizationFilter ? organizationFilter.value : [];
    }, [filter]);

    const handleCloseModal = () => {
        setIsClosing(true);
        setTimeout(() => handleClose(), 300);
    };

    /**
     * Fetches program titles based on the current input value with a debounce delay.
     * If `inputs` is empty, it does nothing.
     * If `selectedOrganization` is present, it adds the organization IDs to the URL.
     * @param {string} inputs - The current input value.
     * @returns {void}
    */
    const fetchProgramTitles = useCallback(async (inputs) => {
        if (!inputs) return;
        let url = `${getProgramUrl}${inputs}`;
        const selectedOrganization = getSelectedOrganization();
        if (selectedOrganization && selectedOrganization.length > 0) {
            url += `?organization=${selectedOrganization.join(",")}`;
        }
        const response = await getProgramTitles(url);
        setProgramOptions(response);
    }, [inputs, getSelectedOrganization]);


    /**
     * Generates a key for filtering data including selected program.
     * @memberof ExportModal
     * @method
     * @param {Array<Filter>} [filters=[]] - The filter array to generate the key from.
     * @param {Program} selectedProgram - The selected program to be included in the filters.
     * @param {string} title - The title to be used in the filters.
     * @returns {Array<Filter>} - The filters with the selected program added.
    */
    const generateFiltersKey = useCallback((filters = [], selectedProgram, title) => {
        const filtersWithProgram = filters.reduce((acc, currFilter) => {
            if (currFilter.value && !['limit', 'page', 'flag'].includes(currFilter.name)) {
                acc.push({ name: currFilter.name, value: currFilter.value });
            }
            return acc;
        }, []);

        if (selectedProgram) {
            const selectedProgramId = selectedProgram.id;
            const programKey = title === "Questions" ? 'programId' : 'programIds';
            const programIndex = filtersWithProgram.findIndex(f => f.name === 'programIds' || f.name === 'programId');

            if (programIndex > -1) {
                filtersWithProgram[programIndex] = {
                    name: programKey,
                    value: programKey === 'programId' ? selectedProgramId : [selectedProgramId]
                };
            } else {
                filtersWithProgram.push({
                    name: programKey,
                    value: programKey === 'programId' ? selectedProgramId : [selectedProgramId]
                });
            }
        }

        return filtersWithProgram;
    }, [selectedProgram]);

    /**
     * Fetches data from the server and exports it to an excel file.
     * @memberof ExportModal
     * @method
     * @async
     * @param {string} title - The title to be used in the excel file.
     * @param {string[]} selectedColumns - The columns to be included in the export.
     * @param {string} program - The selected program to be included in the filters.
     * @returns {void}
    */
    const fetchData = useCallback(async () => {
        const filtersKey = generateFiltersKey(filter, selectedProgram, title);
        try {
            setDownloadMessage(true);
            if (exportModalRef.current) {
                exportModalRef.current.scrollTop = 0;
            }
            const result = await exportDatatableRecord(title, selectedColumns, filtersKey);
            if (result && result.length > 0) {
                const dateTimeFormatted = getCurrentDateTimeFormatted();
                exportToExcel(result, `${title}_ExportedDatatable_${dateTimeFormatted}`);
                setDownloadMessage(true);
            } else {
                setExportFailed(true);
            } 
        } catch (error) {
            setExportFailed(true);
        } finally {
            handleClose();
        }
    }, [title, selectedColumns, generateFiltersKey, handleClose]);

    /**
     * Filters out columns which should not be exported for the given program,
     * and maps the columns to a JSX array of checkboxes.
     * @param {Object[]} columns - The columns to filter and map.
     * @param {?Object} program - The selected program to be used in the filter.
     * @param {string[]} selectedCols - The columns which are currently selected.
     * @returns {JSX.Element[]} - The filtered and mapped columns.
    */
    const filterAndMapColumns = (columns, program, selectedCols) => {
        const hasReadingProgram = program && program.value === "Reading";
        return columns
            .filter((col) => {
                if (col.key === "passage") {
                    return hasReadingProgram;
                }
                return true;
            })
            .map((col) => (
                <div key={col.key} className="export-column-item" onClick={() => handleCheckboxChange(col.key)}>
                    <input
                        type="checkbox"
                        checked={selectedCols.includes(col.key)}
                        readOnly
                    />
                    <label>{col.label}</label>
                </div>
            ));
    };

    return (
        <div className="export-modal-overlay">
            <div className={`export-modal ${isClosing ? 'closing' : ''}`} ref={exportModalRef}>
                <div className='export-title'>
                    {!downloadMessage ? (
                        <span className="title-text">
                            Export {title} <CloseButton />
                        </span>
                    ) : (
                        <div className="download-message">
                            Please wait for the file to download... <CloseButton />
                        </div>
                    )}
                </div>
                <div className='export-modal-content'>
                    <div className="export-columns">
                        {title === "Questions" && (
                            <div style={{ marginBottom: "1rem" }}>
                                <label><b>Program Filter: <span style={{ color: "red" }}>*</span></b></label>
                                <Select
                                    value={selectedProgram && selectedProgram.length > 0 ? null : selectedProgram}
                                    placeholder={"Search Program"}
                                    options={programOptions}
                                    onChange={(program) => setSelectedProgram(program)}
                                    onInputChange={(inputValue, { action }) => {
                                        setSearchOptions(true);
                                        setIsTyping(action === 'input-change');
                                        setInputs(inputValue);
                                    }}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    isSearchable
                                    formatOptionLabel={formatOptionLabel}
                                    menuPlacement="bottom"
                                    noOptionsMessage={() => noOptionsMessage(isTyping, searchOptions)}
                                    components={{ Option: CheckboxOption }}
                                    styles={{
                                        ...commonStyles,
                                        placeholder: (base) => commonStyles.placeholder(base, selectedProgram?.length > 0),
                                        singleValue: (base) => ({
                                            ...base,
                                            color: "#347bfa",
                                        }),
                                    }}
                                />
                            </div>
                        )}
                        <label><b>Select columns to export: </b></label>
                        <div className="export-columns">
                            {filterAndMapColumns(exportColumns, selectedProgram, selectedColumns)}
                        </div>
                    </div>
                </div>
                <div className="export-modal-footer">
                    <span className="export-button">
                        <DownloadButton
                            clickAction={fetchData}
                            disabled={selectedColumns.length === 0
                                || (selectedProgram.length === 0 && title === "Questions")}
                            exportFailed={exportFailed}
                        />
                    </span>
                </div>
            </div>

        </div>
    );
};

export default ExportModal;