import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image from "../../../Assests/Images/user-profile.png";
import flag from "../../../Assests/Images/flag.svg";
import { getAllCommentsForQuestions, addCommentToQuestion } from '../../../Utils/ApiHandler/QuestionApi';
import sendCommentIcon from "../../../Assests/Images/sendCommentIcon.svg";
import closeIcon from "../../../Assests/Images/Vector.svg";
import {
  CustomModalHeader,
  CustomForm,
  CustomModal,
  Toggle,
  StyledCard,
  CardBody,
  CardText,
  CardTitle,
  CardProfile,
  TimeDisplay,
  CustomModalBody,
  FlagIcon,
  CloseIcon
} from './CommentsModal.styled';

const QuestionCommentsModal = ({ show, handleClose, onSave, questionId, access }) => {
  const [formData, setFormData] = useState({
    id: questionId,
    text: '',
    isFlag: false,
  });
  const [comments, setComments] = useState([]);
  const [isFlagged, setIsFlagged] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (show && questionId) {
      fetchCommentsForQuestion(questionId, isFlagged);
    }
  }, [show, questionId, isFlagged]);

  const fetchCommentsForQuestion = _.debounce(async (questionId) => {
    try {
      const comments = await getAllCommentsForQuestions(questionId, isFlagged);
      if (comments.comments) {
        setComments(comments.comments);
      } else {
        console.error("No comments received.");
      }
    } catch (error) {
      console.error("Error fetching comments: ", error);
    }
  },500);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.text.trim()) {
        toast.error("Please drop a message");
        return;
      }

      const insertData = {
        "id": questionId,
        "text": formData.text,
      };
      await addCommentToQuestion(insertData);

      const newComment = { ...formData, timestamp: new Date().toISOString() };
      setComments([...comments, newComment]);

      setFormData({
        ...formData,
        text: '',
      });

      fetchCommentsForQuestion(questionId);

      onSave();
    } catch (error) {
      console.error("Error in updating data: ", error);
    }
  };

  const getTimeDifference = (commentTime) => {
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(commentTime);
    const seconds = Math.floor(timeDifference / 1000);

    if (seconds < 60) {
      return "just now";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  const handleFlagChange = (e) => {
    const { name, checked } = e.target;
    setIsFlagged(checked);
  };

  const isCommentByLoggedInUser = (authorName) => {
    const fullName = localStorage.getItem("first_name") + " " + localStorage.getItem("last_name");
    return authorName === fullName;
  };

  const capitalizeName = (fullName) => {
    const words = fullName.split(' ');

    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join(' ');
  };




  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Comments</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <CustomModalBody>
        <CustomForm>
          {access && 
          <div className="form-group">
            <Form.Control
              as="textarea"
              name="text"
              placeholder="Drop a comment... "
              value={formData.text}
              onChange={handleChange}
              required
            />
            <div className="send-container">
              <img
                src={sendCommentIcon}
                alt="Send"
                onClick={handleSubmit}
                className="send-comment-icon"
              />
            </div>
          </div>}
          <Form.Group>
            <Toggle>
              <span>Flagged</span>
              <div className="form-switch filter-toggle">
                <input
                  id="toggle-flagged"
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={isFlagged}
                  onChange={handleFlagChange}
                  name="isFlagged"
                />
                <label htmlFor="toggle-flagged" className="label"></label>
              </div>
            </Toggle>
          </Form.Group>
        </CustomForm>
        {comments.slice(0).reverse().map((comment, index) => (
          <StyledCard key={index}>
            <CardBody>
              <CardProfile src={image} />
              <div>
                <div className='card-title-container'>
                  <CardTitle>
                    {comment.commentedBy && isCommentByLoggedInUser(comment.commentedBy)
                      ? `${capitalizeName(comment.commentedBy)} (you)`
                      : (comment.commentedBy ? capitalizeName(comment.commentedBy) : '')}
                  </CardTitle>

                </div>
                <div className='card-text-container'>
                  <CardText>
                    {comment.text}
                  </CardText>
                </div>
                <TimeDisplay>{getTimeDifference(comment.timestamp)}</TimeDisplay>
              </div>
              <div className="icons-container">
                {comment.isFlag && <FlagIcon src={flag}
                  alt="Flagged" className="flag-icon" />}
              </div>
            </CardBody>
          </StyledCard>
        ))}
      </CustomModalBody>
    </CustomModal>
  );
};


export default QuestionCommentsModal;