import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../../Components/Title/Title";
import Loader from "../../../Components/Loader/Loader";
import logo from "../../../Assests/Images/empty-box.svg";
import DataTable from "../../../Components/DataTable/DataTable";
import ExportModal from "Components/Modals/ExportModal/ExportModal";
import TempFilterHeader from "Components/FilterHeader/OptimizedFilterHeader";
import AddLevelModal from "../../../Components/Modals/Program/AddLevelModal";
import DeleteModal from "../../../Components/Modals/DeleteModal/DeleteModal";
import ReactivateModal from "Components/Modals/ReactivateModal/ReactivateModal";
import EditProgramModal from "../../../Components/Modals/Program/EditProgramModal";
import CreateProgramModal from "../../../Components/Modals/Program/CreateProgramModal";
import {
    deleteProgram,
    getAllPrograms,
    publishProgram,
    reactivateProgram,
    unPublishPrograms,
    updateBulkProgramStatus,
} from "../../../Utils/ApiHandler/ProgramApi";

const columns = [
    "Code",
    "Program Name",
    "Organization",
    "Levels",
    "Description",
];

const STATUS = {
    UNPUBLISH: -1,
    PUBLISH: 1,
    IN_REVIEW: 0,
    NO_IMAGE: 2,
};

const exportColumns = [
    { key: "programCode", label: "Program Code" },
    { key: "programName", label: "Program Name" },
    { key: "numLevels", label: "Number of Levels" },
    { key: "numDomains", label: "Number of Domains" },
    { key: "numTopics", label: "Number of Topics" },
    { key: "numSkills", label: "Number of Skills" },
    { key: "numQuestions", label: "Number of Questions" },
    { key: "status", label: "Status" },
];

const filtersDropdownStructure = [{
    "code": true,
    "organization": true,
    "program": true,
    "status": true,
    "level": false,
    "flag": false,
    "skill": false,
    "topic": false,
    "domain": false,
    "question": false,
    "flaggedBy": false,
    "complexity": false,
    "questionType": false,
}];

export function CurriculumAllPrograms() {

    const navigate = useNavigate();

    const [status, setStatus] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [actionData, setActionData] = useState("");
    const [deleteRole, setDeleteRole] = useState("");
    const [addLevel, showAddLevel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFetched, setDataFetched] = useState(false);
    const [editProgram, showEditProgram] = useState(false);
    const [deleteModel, showDeleteModel] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [reactivateRole, setReactivateRole] = useState("");
    const [publishAccess, setPublishAccess] = useState(false);
    const [showAddProgram, setShowAddProgram] = useState(false);
    const [addLevelAccess, setAddLevelAccess] = useState(false);
    const [programsAccess, setProgramsAccess] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [unpublishAccess, setUnpublishAccess] = useState(false);
    const [dataFilterStatus, setDataFilterStatus] = useState(true);
    const [reactivateModel, showReactivateModel] = useState(false);
    const [goToPage, setGoToPage] = useState(0);
    const [filter, setFilter] = useState([
        { name: "page", value: currentPage },
        { name: "limit", value: 10 },
        { name: "active", value: dataFilterStatus },
        { name: "status", value: "" },
        { name: "search", value: "" },
        { name: "title", value: [] },
    ]);
    const [isHavingExportAccess, setIsHavingExportAccess] = useState(false);

    useEffect(() => {
        grantAccess();
    }, [])
    useEffect(() => {
        if (goToPage > 1) {
            setCurrentPage(goToPage);
        }
        else {
            setCurrentPage(1);
        }
        navigate(`/curriculum/program/${currentPage}`);
    }, [filter, goToPage]);

    useEffect(() => {
        refreshDatatable();
    }, [filter, status, dataFilterStatus]);

    const grantAccess = () => {
        DbConfig.curriculumAccess.toArray().then(curriculumAccessData => {
            setAddLevelAccess(curriculumAccessData.map(item => item.levels));
            setProgramsAccess(curriculumAccessData.map(item => item.programs));
            setPublishAccess(curriculumAccessData.map(item => item.publishCurriculum));
            setUnpublishAccess(curriculumAccessData.map(item => item.unPublishCurriculum));
            setIsHavingExportAccess(curriculumAccessData[0].exportCurriculum);
        })
    }

    const refreshDatatable = _.debounce(() => {
        setIsLoading(true);
        const updatedFilter = [
            ...filter.filter(f => f.name !== "active"),
            { name: "active", value: dataFilterStatus }
        ];
        getAllPrograms(updatedFilter).then((data) => {
            setTableData(data.programs.map(extractFields));
            setTotalPageCount(data.totalPages);
            setIsLoading(false);
            if (data.programs && data.programs.length > 0) {
                setDataFetched(true);
            }
        });
    }, 1000);

    const handleAction = (actionName, showFunction) => {
        setIsBlur(true);
        showFunction(true);
        const action = actions.find(action => action.name === actionName);
        if (action.data.title) {
            action.data.program = action.data.title;
        }
        if (action) setActionData(action.data);
    };

    const handleCreateProgram = () => {
        setIsBlur(true);
        setShowAddProgram(true)
    };

    const findReactivateAction = () => {
        const action = inActivateOptions.find(action => action.name === "reactivate");
        if (action) return { title: action.data.program, programId: action.data._id };
        return null;
    };

    const handleReactivate = () => {
        setIsBlur(true);
        const actionData = findReactivateAction();
        if (actionData) setActionData([actionData]);
        setReactivateRole("Program");
        showReactivateModel(true);
    };

    const handleReactivateAction = async () => {
        try {
            const data = { programId: actionData[0].programId, }
            await reactivateProgram(data);
            showReactivateModel(false);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsBlur(false);
        }
    };

    const findDeleteAction = () => {
        const action = actions.find(action => action.name === "delete");
        if (action) {
            return [{
                title: action.data.program,
                programIds: [action.data._id],
                child: action.data.levels,
            }];
        }
        return [];
    };

    const handleDelete = () => {
        setIsBlur(true);
        setActionData(findDeleteAction());
        setDeleteRole("Program");
        showDeleteModel(true);
    };

    const handleDeleteAction = async () => {
        try {
            const programIds = actionData[0]?.programIds || [];
            await deleteProgram({ programIds });
            showDeleteModel(false);
            setIsLoading(true);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsBlur(false);
        }
    };

    const collectProgramIds = (actionName) => {
        const programIds = { programIds: [] };
        const action = BulkActions.find(action => action.name === actionName);
        if (action) action.data.forEach(eachAction => { programIds.programIds.push(eachAction.id); });
        return programIds;
    };

    const handleBulkActions = (actionType) => {
        const actionMap = {
            publish: () => {
                const programIds = collectProgramIds("publish");
                setActionData(programIds);
                handleBulkPublishUnpublish(true, programIds);
            },
            unpublish: () => {
                const programIds = collectProgramIds("unpublish");
                setActionData(programIds);
                handleBulkPublishUnpublish(false, programIds);
            },
            delete: () => {
                showDeleteModel(true);
                const programIds = collectProgramIds("delete");
                setActionData(programIds);
                setDeleteRole("MultiplePrograms");
            }
        };
        if (actionMap[actionType]) {
            setIsBlur(true);
            actionMap[actionType]();
        }
    };

    const handleBulkPublishUnpublish = async (publish, data) => {
        try {
            await updateBulkProgramStatus(publish, data);
            setStatus(!status);
            refreshDatatable();
            setIsLoading(true);
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsBlur(false);
        }
    };

    const handlePageChange = (currentPage, itemsPerPage) => {
        setCurrentPage(currentPage);
        setGoToPage(0);
        setIsLoading(true);
        const updatedFilter = filter.map(f => f.name === "page"
            ? { ...f, value: currentPage } : f.name === "limit" ? { ...f, value: itemsPerPage } : f);
        const newFilters = [...updatedFilter,
        !updatedFilter.some(f => f.name === "page") && { name: "page", value: currentPage },
        !updatedFilter.some(f => f.name === "limit") && { name: "limit", value: itemsPerPage }
        ].filter(Boolean);
        setFilter(newFilters);
    };

    const handlePublishStatus = async () => {
        try {
            const action = actions.find(action => action.name === "publishStatus");
            if (action) {
                const isPublished = action.data.status === STATUS.PUBLISH;
                const newStatus = isPublished ? STATUS.UNPUBLISH : STATUS.PUBLISH;
                const data = { programIds: [action.data._id], status: newStatus };
                isPublished ? await unPublishPrograms(data) : await publishProgram(data);
                setStatus(prevStatus => !prevStatus);
                setIsLoading(true);
                // refreshDatatable();
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const actions = [
        {
            Label: "publish/unpublish",
            name: "publishStatus",
            clickAction: handlePublishStatus,
            access: publishAccess[0] || unpublishAccess[0],
            publishAccess: publishAccess[0],
            unPublishAccess: unpublishAccess[0]
        }, {
            Label: "Add Level",
            name: "addLevel",
            clickAction: () => handleAction("addLevel", showAddLevel),
            access: addLevelAccess[0]?.add,
            id: "add-level-action"
        }, {
            Label: "Edit",
            name: "editProgram",
            clickAction: () => handleAction("editProgram", showEditProgram),
            access: programsAccess[0]?.edit
        }, {
            Label: "Delete",
            name: "delete",
            clickAction: handleDelete,
            class: "text-delete",
            id: "delete-program-action",
            access: programsAccess[0]?.delete
        },
    ];

    const extractFields = (obj) => {
        const filterKey = "program"
        const handleClick = async () => {
            window.open(`/curriculum/level/${currentPage}/${obj._id}/${filterKey}`, '_blank');
        };

        const subRecordLink = (
            <span
                onClick={handleClick}
                className="record-count-link"
            >
                {obj.subRecordCount}
            </span>
        );

        const {
            code,
            title,
            organization,
            subRecordCount,
            description,
            status,
            _id,
            parent
        } = obj;

        return {
            code,
            title,
            organization,
            subRecordLink,
            description,
            status,
            _id,
            parent
        };
    };

    const inActivateOptions = [{
        Label: "Reactivate",
        name: "reactivate",
        clickAction: handleReactivate,
        access: publishAccess[0],
        access: unpublishAccess[0]
    }];

    const BulkActions = [
        {
            Label: "Publish All",
            name: "publish",
            clickAction: (() => handleBulkActions("publish")),
            access: publishAccess[0],
        }, {
            Label: "Unpublish All",
            name: "unpublish",
            clickAction: (() => handleBulkActions("unpublish")),
            access: unpublishAccess[0]

        }, {
            Label: "Delete All",
            name: "delete",
            class: "text-delete",
            clickAction: (() => handleBulkActions("delete")),
            access: programsAccess[0]?.delete
        }
    ];

    const handleClose = (closeModal) => {
        setIsBlur(false);
        closeModal(false);
    }

    return (
        <>
            <Title titleContent="Programs" />
            <div className={`curriculum-all-topics ${isBlur ? 'blur' : ''}`}>
                {dataFetched ? (
                    <>
                        <TempFilterHeader
                            filter={filter}
                            setFilter={setFilter}
                            pageName={"Programs"}
                            setIsBlur={setIsBlur}
                            setIsLoading={setIsLoading}
                            createButtonText="+ Add program"
                            dataFilterStatus={dataFilterStatus}
                            setShowExportModal={setShowExportModal}
                            handleCreateAction={handleCreateProgram}
                            setDataFilterStatus={setDataFilterStatus}
                            showCreateButton={programsAccess[0]?.add}
                            filtersDropdown={filtersDropdownStructure}
                            setGoToPage={setGoToPage}
                            isPageChange={goToPage}
                            isHavingExportAccess={isHavingExportAccess}
                        />
                        <DataTable
                            columns={columns}
                            data={tableData}
                            actions={actions}
                            totalCount={totalPageCount}
                            BulkActionOptions={BulkActions}
                            filter={filter}
                            setFilter={setFilter}
                            handlePageChange={handlePageChange}
                            dataStatus={true}
                            setGoToPage={setGoToPage}
                            inActivateOptions={inActivateOptions}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        {addLevel && (
                            <AddLevelModal
                                show={showAddLevel}
                                handleClose={() => handleClose(showAddLevel)}
                                parentIdentifier={actionData}
                                onSave={refreshDatatable}
                            />)}
                        {editProgram && (
                            <EditProgramModal
                                show={editProgram}
                                handleClose={() => handleClose(showEditProgram)}
                                initialData={actionData}
                                onSave={refreshDatatable}
                            />)}
                        {reactivateModel && (
                            <ReactivateModal
                                showModal={reactivateModel}
                                role={reactivateRole}
                                onClose={() => handleClose(showReactivateModel)}
                                reactivateData={actionData}
                                onClick={handleReactivateAction}
                            />)}
                        {deleteModel && (
                            <DeleteModal
                                showModal={deleteModel}
                                role={deleteRole}
                                onClose={() => handleClose(showDeleteModel)}
                                onSave={refreshDatatable}
                                deleteData={actionData}
                                onClick={handleDeleteAction}
                            />)}
                    </>
                ) : !isLoading && (
                    <div className="empty-Container">
                        <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />
                        <b><p className="text-center" style={{ color: "green" }}>
                            You haven't created any Program yet.!
                        </p></b>
                        <p className="text-center">Click add program to create your first Program.</p>
                        <button onClick={handleCreateProgram} type="button" className="btn btn-primary">
                            + Add Program
                        </button>
                    </div>
                )}
            </div>
            {isLoading && (<div className="spinner-overlay"><Loader /></div>)}
            {showAddProgram && (
                <CreateProgramModal
                    show={showAddProgram}
                    handleClose={() => handleClose(setShowAddProgram)}
                    onSave={refreshDatatable}
                />)}
            {showExportModal && (
                <ExportModal
                    title="Programs"
                    filter={filter}
                    exportColumns={exportColumns}
                    handleClose={() => handleClose(setShowExportModal)}
                />
            )}
        </>
    );
}
export default CurriculumAllPrograms;