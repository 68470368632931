import _ from "lodash";
import DbConfig from "Constants/db";
import _debounce from "lodash/debounce";
import { TbFilterX } from "react-icons/tb";
import { FilterWrapper } from "./FilterHeader.styled";
import filterIcon from "../../Assests/Images/filter_1.svg";
import ExportIcon from "../../Assests/Icons/ExportIcon.svg";
import Dropdown from "Components/FilterHeader/GenericDropdown";
import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { fetchQuestionTypes, fetchOrganizationsTitle, } from "Utils/ApiHandler/FilterDropdownsApiCalls";
import { handleStateChange, handleSelectionChange, fetchAndSetData, fetchDropdown } from "../../Utils/FilterUtils";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


const genericStatusDropdown = [
    { value: -1, label: "Unpublish" },
    { value: 1, label: "Publish" },
];

const questionPageSpecficStatusDropdown = [
    { value: 0, label: "In review" },
    { value: 2, label: "Picture pending" },
];

const flagDropdown = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
];

const complexityDropdown = [
    { label: "Low", value: "l" },
    { label: "Medium", value: "m" },
    { label: "High", value: "h" },
    { label: "Challenging", value: "c" },
];

const TempFilterHeader = ({
    filter,
    setFilter,
    pageName,
    setIsBlur,
    setIsLoading,
    showMoreFilters,
    filtersDropdown,
    showCreateButton,
    handleMoreFilter,
    dataFilterStatus,
    createButtonText,
    handleCreateAction,
    setShowExportModal,
    setDataFilterStatus,
    moreFiltersDropdown,
    setGoToPage,
    isPageChange,
    isHavingExportAccess
}) => {
    const dropdownRefs = {
        status: useRef(null),
        organization: useRef(null),
        code: useRef(null),
        program: useRef(null),
        level: useRef(null),
        domain: useRef(null),
        topic: useRef(null),
        skill: useRef(null),
        questionType: useRef(null),
        flag: useRef(null),
        complexity: useRef(null)
    };

    const [search, setSearchInput] = useState("");
    const [goToPage, setGoToPageInput] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(0);
    const [resetStatus, setResetStatus] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);
    const [searchOptions, setSearchOptions] = useState(true);
    const [exportAccess, setExportAccess] = useState(isHavingExportAccess);

    const navigate = useNavigate();
    const { page, id, filterKey } = useParams();

    const [selections, setSelections] = useState({
        status: "",
        flag: "",
        organization: [],
        code: [],
        program: [],
        level: [],
        domain: [],
        topic: [],
        skill: [],
        questionType: [],
        complexity: []
    });
    const [inputs, setInputs] = useState({
        code: "",
        program: "",
        level: "",
        domain: "",
        topic: "",
        skill: "",
        questionType: "",
        complexity: ""
    });
    const [titles, setTitles] = useState({
        code: [],
        organization: [],
        program: [],
        level: [],
        domain: [],
        topic: [],
        skill: [],
        status: '',
        questionType: []
    });
    const [menuStates, setMenuStates] = useState({
        status: false,
        organization: false,
        code: false,
        program: false,
        level: false,
        domain: false,
        topic: false,
        skill: false,
        questionType: false,
        flag: false,
        complexity: false
    });

    const filterKeyMap = {
        'level': pageName === "Levels" ? 'title' : 'levelIds',
        'program': pageName === "Programs" ? 'title' : 'programIds',
        'organization': pageName === "Organizations" ? 'title' : 'organizationIds',
        'domain': pageName === "Domains" ? 'title' : 'domainIds',
        'skill': pageName === "Skills" ? 'title' : 'skillIds',
        'topic': pageName === "Topics" ? 'title' : 'topicIds',
        'questionType': 'qtype',
        'complexity': 'difficulty',
        'status': 'status',
        'code': 'code',
        'flag': 'flag',
    };

    const pageFlags = [
        pageName === "Programs",
        pageName === "Users",
        pageName === "Teams",
        pageName === "Roles",
        pageName === "Organizations"
    ];

    const toggleButtonClassName = pageFlags.some(flag => flag)
        ? "form-switch program-page-toggle" : "form-switch filter-toggle";

    const statusDropdown = pageName === "Questions" ?
        [...genericStatusDropdown, ...questionPageSpecficStatusDropdown] : genericStatusDropdown;

    const moreFiltersNameArray = moreFiltersDropdown?.length > 0
        ? Object.keys(moreFiltersDropdown[0]).filter(key => moreFiltersDropdown[0][key]) : [];

    const filtersNameArray = filtersDropdown?.length > 0
        ? Object.keys(filtersDropdown[0]).filter(key => filtersDropdown[0][key]) : [];

    const dropdownTitles = useMemo(() => ({
        status: statusDropdown,
        flag: flagDropdown,
        complexity: complexityDropdown,
        organization: titles.organization,
        code: titles.code,
        program: titles.program,
        level: titles.level,
        domain: titles.domain,
        topic: titles.topic,
        skill: titles.skill,
        questionType: titles.questionType,
    }), [statusDropdown, titles]);

    const grantAccess = () => {
        DbConfig.curriculumAccess.toArray().then((curriculumAccessData) => {
            // setExportAccess(curriculumAccessData.map(item => item.exportCurriculum));
        });
    }

    useEffect(() => {
        grantAccess();
    }, []);

    useEffect(() => {
        fetchOrganization();
        if (pageName === "Questions") fetchQuestionTypesDropdown();
    }, [pageName]);

    useEffect(() => {
        if (isPageChange === 0) setGoToPageInput("");
    }, [isPageChange]);

    useEffect(() => {
        if (!searchOptions) {
            setIsTyping(false);
        }
    }, [searchOptions]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchAndSetData(inputs, pageName, selections, dataFilterStatus, setSearchOptions, setTitles);
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [inputs, pageName, selections, dataFilterStatus]);

    useEffect(() => {
        let filterCount = 0;
        let isAnyFilterActive = false;
        const filterNamesSet = new Set([
            ...moreFiltersNameArray,
            ...(filtersNameArray)
        ]);
        if (search.trim() !== "") {
            isAnyFilterActive = true;
        }
        if (goToPage.trim() !== "") {
            isAnyFilterActive = true;
        }
        filterNamesSet.forEach(filterName => {
            const processedFilterName = filterKeyMap[filterName] || filterName;
            const filterItem = filter.find(el => el.name === processedFilterName);
            if (filterItem) {
                const { value } = filterItem;
                if (moreFiltersNameArray.includes(filterName)) {
                    if (Array.isArray(value) && value.length > 0) {
                        filterCount++;
                        isAnyFilterActive = true;
                    } else if (typeof value === 'string' && value.trim() !== '') {
                        filterCount++;
                        isAnyFilterActive = true;
                    } else if (typeof value === 'number' && value !== 0) {
                        filterCount++;
                        isAnyFilterActive = true;
                    }
                } else if (
                    (Array.isArray(value) && value.length > 0) ||
                    (typeof value === 'string' && value.trim() !== '') ||
                    (typeof value === 'number' && value !== 0) ||
                    (typeof value === 'boolean')
                ) {
                    isAnyFilterActive = true;
                }
            }
        });
        setResetStatus(isAnyFilterActive);
        setActiveFilters(filterCount);
    }, [filter, filtersDropdown, moreFiltersNameArray, filterKeyMap]);


    const fetchOrganization = fetchDropdown(fetchOrganizationsTitle, "organization", setTitles);

    const fetchQuestionTypesDropdown = fetchDropdown(fetchQuestionTypes, "questionType", setTitles);

    const debouncedSearch = useCallback(
        _debounce((value, inputLabel) => {
            let previousFilter = filter.filter((f) => f.name !== inputLabel);
            let updatedFilter = [
                ...previousFilter,
                {
                    name: inputLabel,
                    value: value,
                },
            ];
            setFilter(updatedFilter);
        }, 1000),
        [filter]
    );

    const handleSearchInput = (event, setFuncName, inputLabel) => {
        setFuncName(event.target.value);
        if (inputLabel == "page") {
            setGoToPage(event.target.value);
            if (!event.target.value) {
                event.target.value = 1;
            }
        }
        else {
            setGoToPage(0);
        }
        debouncedSearch(event.target.value, inputLabel);
    };

    const handleFilterChange = (filterName, selectedOptions) => {
        let selectedValues;
        const singleSelectFilters = ['status', 'flag', 'qtype'];
        if (singleSelectFilters.includes(filterName)) {
            selectedValues = selectedOptions ? selectedOptions.value : '';
        } else {
            selectedValues = Array.isArray(selectedOptions)
                ? selectedOptions.map(option => option.value) : [selectedOptions.value];
        }
        const updatedFilter = filter.filter(f => f.name !== filterName && f.name !== "page");
        updatedFilter.push({ name: filterName, value: selectedValues }, { name: "page", value: 1 });
        setFilter(updatedFilter);
    };

    const handleActiveInactiveData = (e) => {
        const isChecked = e.target.checked;
        setIsChecked(isChecked);
        handleResetFilter();
        setSearchInput("");
        setGoToPageInput("");
        setFilter((prevFilters) => {
            const updatedFilters = [...prevFilters];
            const activeFilterIndex = updatedFilters.findIndex(f => f.name === 'active');
            if (activeFilterIndex !== -1) {
                updatedFilters[activeFilterIndex].value = isChecked;
            } else {
                updatedFilters.push({ name: 'active', value: isChecked });
            }
            return updatedFilters;
        });
        setDataFilterStatus(isChecked);
        // Object.values(dropdownRefs).forEach(ref => {
        //     if (ref.current) ref.current.setValue([]);
        // });
        setTimeout(() => setActiveFilters(0), 2000);
    };

    const handleResetFilter = () => {
        const defaultInputs = {
            code: '',
            program: '',
            level: '',
            domain: '',
            topic: '',
            skill: '',
            questionType: '',
            complexity: ''
        };
        const defaultTitles = {
            code: [],
            organization: [],
            program: [],
            level: [],
            domain: [],
            topic: [],
            skill: [],
            status: '',
            questionType: []
        };
        const defaultSelections = {
            status: "",
            flag: "",
            organization: [],
            code: [],
            program: [],
            level: [],
            domain: [],
            topic: [],
            skill: [],
            questionType: [],
            complexity: []
        };
        const updatedFilter = [
            { name: "page", value: 1 },
            { name: "limit", value: 10 },
            { name: "active", value: dataFilterStatus },
            { name: "status", value: "" },
            { name: "search", value: "" },
            { name: "title", value: [] },
            { name: "code", value: [] },
        ];

        const routes = {
            program: `/curriculum/level/${page}`,
            level: `/curriculum/domain/${page}`,
            domain: `/curriculum/topic/${page}`,
            topic: `/curriculum/skill/${page}`,
            skill: `/curriculum/questions/${page}`,
        };
        setSearchInput("");
        setGoToPageInput("");
        setInputs(defaultInputs);
        setTitles(defaultTitles);
        setResetTrigger(prev => prev + 1)
        setSelections(defaultSelections);
        setFilter(updatedFilter);
        fetchOrganization();
        setIsLoading(true);
        if (filterKey in routes) {
            navigate(routes[filterKey], { replace: true })
        }
    };

    const getDropdownProps = (filterKey) => ({
        id: filterKey,
        dropdownRef: dropdownRefs[filterKey],
        selections: selections,
        setSelections: setSelections,
        titles: dropdownTitles[filterKey] || [],
        handleSelectionChange,
        handleInputChange: handleStateChange,
        setInputs,
        setSearchOptions,
        searchOptions,
        isTyping,
        setIsTyping,
        isSearchable: filterKey !== 'status' && filterKey !== 'flag' && filterKey !== 'complexity',
        menuState: menuStates[filterKey],
        setMenuStates: setMenuStates,
        handleMenuStateChange: handleStateChange,
        dropdownClassName: 'filter-dropdown',
        resetTrigger: resetTrigger
    });

    const renderFilterComponents = () => {
        return Object.keys(filtersDropdown[0]).map((filterKey) => {
            if (!filtersDropdown[0][filterKey]) return null;
            const dropdownProps = getDropdownProps(filterKey);
            return (
                <Dropdown
                    {...dropdownProps}
                    onChangeCallback={(selectedOptions) => {
                        const updatedFilterKey = filterKeyMap[filterKey];
                        handleFilterChange(updatedFilterKey, selectedOptions);
                    }}
                />
            );
        });
    };


    useEffect(() => {
        if (id) {
            handleSelectionChange(filterKey, id, setSelections);
        } else {
            handleSelectionChange(filterKey, [], setSelections);
        }
    }, [id]);

    console.log('is having export access', isHavingExportAccess)
    return (
        <FilterWrapper>
            <div className="filter-container">
                {/* <div className="input-search-container">
                    <div className="input-search-wrapper">
                        <input
                            className="input-search"
                            type="number"
                            placeholder="Page"
                            min="1"
                            onChange={event => handleSearchInput(event, setGoToPageInput, 'page')}
                            value={goToPage}
                        />
                    </div>
                </div> */}
                <div className="search-container">
                    <div className="search-wrapper">
                        <i className="fas fa-search search-icon"></i>
                        <input
                            className="search"
                            type="search"
                            placeholder="Search"
                            onChange={event => handleSearchInput(event, setSearchInput, 'search')}
                            value={search}
                            styles={{
                                placeholder: (base) => ({
                                    ...base,
                                    paddingLeft: "0.25rem",
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="filter-dropdown-container">
                    {renderFilterComponents()}
                    {showMoreFilters && (
                        <div className="moreFilters-container">
                            <button
                                className={activeFilters ? "moreFilterActive" : "moreFilters"}
                                onClick={() => handleMoreFilter()}
                            >
                                <img className="moreFilters-icon" src={filterIcon} alt="Logo" />
                                More Filters{activeFilters ? "(" + activeFilters + ")" : ""}
                            </button>
                        </div>
                    )}
                    {resetStatus && (
                        <div className="reset-Filter">
                            <TbFilterX style={{ width: "24px", height: "24px", color: "#7A8892", cursor: "pointer", }}
                                onClick={handleResetFilter}
                            />
                        </div>
                    )}
                    {isHavingExportAccess && (<span className="export-icon" onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}>
                        <img src={ExportIcon} onClick={() => {
                            setIsBlur(true);
                            setShowExportModal(true)
                        }} />
                        {showTooltip && <div className="custom-tooltip">Click to Export {pageName}</div>}
                    </span>
                    )}
                </div>
                <div className={toggleButtonClassName}>
                    <>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={isChecked}
                            onChange={handleActiveInactiveData}
                        />
                        <label className="label" htmlFor="flexSwitchCheckDefault">
                            {isChecked ? `Active ${pageName}` : `Inactive ${pageName}`}
                        </label>
                    </>
                </div>
                <div className="create-program-button">
                    {showCreateButton && isChecked && (
                        <button className="add-program-button" onClick={handleCreateAction} data-testid="add-button">
                            {createButtonText}
                        </button>
                    )}
                </div>
            </div>
        </FilterWrapper>)
}

export default TempFilterHeader