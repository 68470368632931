import _ from "lodash";
import DbConfig from "Constants/db";
import parse from "html-react-parser";
import { Suspense, lazy } from "react";
import React, { useState, useEffect } from "react";
import Title from "../../../Components/Title/Title";
import Loader from "../../../Components/Loader/Loader";
import logo from "../../../Assests/Images/empty-box.svg";
import { useNavigate, useParams } from "react-router-dom";
import SaveModal from "Components/Modals/SaveModal/SaveModal";
import MoreFilters from "Components/FilterHeader/MoreFilters";
import TempFilterHeader from "Components/FilterHeader/OptimizedFilterHeader";
import DeleteModal from "../../../Components/Modals/DeleteModal/DeleteModal";
import ReactivateModal from "Components/Modals/ReactivateModal/ReactivateModal";
import QuestionCommentsModal from "../../../Components/Modals/Comments/QuestionCommentsModal";
import FlagInappropriateModal from "../../../Components/Modals/FlagInappropriateModal/FlagInappropriateModal";
import {
    addFlag,
    removeFlag,
    deleteQuestion,
    getAllQuestions,
    publishQuestion,
    reactivateQuestion,
    unPublishQuestions,
    unFlagQuestionForBulk,
    updateBulkQuestionStatus,
    addFlagForBulkForQuestion,
} from "../../../Utils/ApiHandler/QuestionApi";
import ExportModal from "Components/Modals/ExportModal/ExportModal";

const DataTable = lazy(() => import("Components/DataTable/DataTable"));

const PreviewModal = lazy(() => import("Components/Modals/Questions/PreviewModal"));

const columns = ["Code", "", "Question", "Skill"];

const STATUS = {
    UNPUBLISH: -1,
    PUBLISH: 1,
    PICTURE_PENDING: 2,
    IN_REVIEW: 0,
};

const flagging = [];

const comments = [];

const publish = [];

const unpublish = [];

const questions = [];

const moreFiltersDropdown = [{
    "code": true,
    "level": true,
    "topic": true,
    "flag": false,
    "domain": true,
    "skill": false,
    "program": true,
    "status": false,
    "question": false,
    "flaggedBy": true,
    "organization": true,
}];

const filtersDropdownStructure = [{
    "skill": true,
    "status": true,
    "flag": true,
    "complexity": true,
    "questionType": true,
    "code": false,
    "level": false,
    "topic": false,
    "domain": false,
    "program": false,
    "question": false,
    "flaggedBy": false,
    "organization": false,
}];

const exportColumns = [
    { key: "questionCode", label: "Question Code" },
    { key: "questionText", label: "Question Text/Passage" },
    { key: "questionImage", label: "Question Image" },
    // { key: "passage", label: "Passage" },
    { key: "status", label: "Status" },
    { key: "answer", label: "Answer" },
    { key: "options", label: "Options" },
    { key: "optionImages", label: "Option Images" },
    { key: "skillName", label: "Skill Name" },
    { key: "topicName", label: "Topic Name" },
    { key: "domainName", label: "Domain Name" },
    { key: "levelName", label: "Level Name" },
    { key: "programName", label: "Program Name" },
];

function CurriculumAllQuestions() {

    const { page, id, filterKey } = useParams();
    const navigate = useNavigate();

    const [isBlur, setIsBlur] = useState(false);
    const [status, setStatus] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalUrl, setModalUrl] = useState("");
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [actionData, setActionData] = useState("");
    const [deleteRole, setDeleteRole] = useState("");
    const [questionId, setQuestionId] = useState(null);
    const [flagComment, setFlagComment] = useState("");
    const [dataFetched, setDataFetched] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModel, showDeleteModel] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [reactivateRole, setReactivateRole] = useState("");
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [publishAccess, setPublishAccess] = useState(false);
    const [commentsAccess, setCommentsAccess] = useState(false);
    const [flaggingAccess, setFlaggingAccess] = useState(false);
    const [unpublishAccess, setUnpublishAccess] = useState(false);
    const [questionsAccess, setQuestionsAccess] = useState(false);
    const [shouldShowArrow, setShouldShowArrow] = useState(false);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [displayComments, showDisplayComments] = useState(false);
    const [dataFilterStatus, setDataFilterStatus] = useState(true);
    const [reactivateModel, showReactivateModel] = useState(false);
    const [isQuestionsPage, setIsQuestionsPage] = useState(false);
    const [showFlagInAppropriate, setShowFlagInAppropriate] = useState(false);
    const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
    const [goToPage, setGoToPage] = useState(0);
    const [isHavingExportAccess, setIsHavingExportAccess] = useState(false);

    const pathName = window.location.pathname;
    useEffect(() => {
        setIsQuestionsPage(
            pathName.includes(`/curriculum/questions/${currentPage}`)
        );
    }, [pathName]);

    const [filter, setFilter] = useState([
        { name: "page", value: 1 },
        { name: "limit", value: 10 },
        { name: "active", value: true },
        { name: "status", value: "" },
        { name: "search", value: "" },
        { name: "title", value: [] },
        { name: "skillIds", value: [] }
    ]);
    const urlfilter = JSON.stringify(filter);
    const encodedFilter = encodeURIComponent(urlfilter)
    const initializeFilters = () => [
        { name: "page", value: 1 },
        { name: "limit", value: 10 },
        { name: "active", value: dataFilterStatus },
        { name: "status", value: "" },
        { name: "search", value: "" },
        { name: "title", value: [] },
    ];

    const grantAccess = () => {
        DbConfig.curriculumAccess.toArray().then((curriculumAccessData) => {
            curriculumAccessData.forEach((item) => {
                flagging.push(item.flagging);
                comments.push(item.comments);
                publish.push(item.publishCurriculum);
                unpublish.push(item.unPublishCurriculum);
                questions.push(item.questions);
            });
            setFlaggingAccess(flagging);
            setCommentsAccess(comments);
            setPublishAccess(publish);
            setUnpublishAccess(unpublish);
            setQuestionsAccess(questions);
            setIsHavingExportAccess(curriculumAccessData[0].exportCurriculum);
        });
    };

    useEffect(() => {
        grantAccess();
    }, []);

    useEffect(() => {
        grantAccess();
    }, [currentPage]);
    useEffect(() => {
        refreshDatatable();
    }, [filter, status]);

    useEffect(() => {
        if (goToPage > 1) {
            setCurrentPage(goToPage);
        }
        else if (filter && goToPage !== -1) {
            setCurrentPage(1);
        }
        navigate(`/curriculum/questions/${currentPage}`);
    }, [filter, goToPage]);


    const handleReactivate = () => {
        const action = inActivateOptions.find(
            (action) => action.name === "reactivate"
        );
        if (action) {
            const data = [{
                title: action.data.questionTitle,
                _id: action.data._id,
            }];
            setActionData(data);
            setReactivateRole("Question");
            setIsBlur(true);
            showReactivateModel(true);
        }
    };

    const handleReactivateAction = async () => {
        try {
            const { _id: questionId } = actionData[0];
            await reactivateQuestion({ questionId });
            showReactivateModel(false);
            setIsBlur(false);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleDelete = () => {
        setIsBlur(true);
        showDeleteModel(true);
        const action = actions.find((action) => action.name === "delete");
        if (action) {
            const data = [{
                title: action.data.questionTitle,
                questionIds: [action.data._id],
            }];
            setActionData(data);
            setDeleteRole("Question");
        }
    };

    const handleDeleteQuestion = async () => {
        try {
            const { questionIds } = Array.isArray(actionData) ? actionData[0] : actionData;
            setIsLoading(true);
            await deleteQuestion({ questionIds });
            showDeleteModel(false);
            setIsBlur(false);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handlePublishStatus = async () => {
        try {
            const action = actions.find((action) => action.name === "publishStatus");
            if (action) {
                const newStatus = action.data.status === STATUS.PUBLISH ? STATUS.UNPUBLISH : STATUS.PUBLISH;
                const data = { questionIds: [action.data._id], status: newStatus, };
                setIsLoading(true);
                newStatus === STATUS.UNPUBLISH ? await unPublishQuestions(data) : await publishQuestion(data);
                setStatus((prevStatus) => !prevStatus);
                // refreshDatatable();
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleDisplayComments = () => {
        const action = actions.find((action) => action.name === "displayComments");
        if (action) {
            setActionData(action.data.question);
            setQuestionId(action.data._id);
        }
        setIsBlur(true);
        showDisplayComments(true);
    };

    const handleActions = (actionType) => {
        const action = actions.find(
            (action) =>
                (actionType === "flagInappropriate" && action.name === "flag") ||
                (actionType === "editQuestion" && action.name === "editQuestion")
        );
        if (action) {
            if (actionType === "flagInappropriate") {
                setShowFlagInAppropriate(true);
                setActionData(action.data);
            } else if (actionType === "editQuestion") {
                const encodedDataId = encodeURIComponent(action.data._id);
                const url =
                    `/curriculum/editQuestion?id=${encodedDataId}&skillName=${action.data.skill}&title=Edit Question`;
                setModalUrl(url);
                setIsModalOpen(true);
                setIsEdit(true);
                setShouldShowArrow(true);
            }
        }
    };

    const handleBulkActions = (actionType) => {
        const questionIds = { questionIds: [] };
        const ids = { ids: [] };
        const action = BulkActions.find(action => action.name === actionType);
        if (action) {
            action.data.forEach(eachAction => {
                if (action.name === "removeFlag") ids.ids.push(eachAction.id);
                else questionIds.questionIds.push(eachAction.id);
            });
            setActionData(questionIds);
            if (actionType === "publish" || actionType === "unpublish") {
                const isPublish = actionType === "publish";
                handleBulkPublishUnpublish(isPublish, questionIds);
            } else if (actionType === "delete") {
                setIsBlur(true);
                showDeleteModel(true);
                setDeleteRole("MultipleQuestions");
            } else if (actionType === "flag") {
                setIsBlur(true);
                setShowFlagInAppropriate(true);
                setDeleteRole("MultipleQuestions");
            } else if (actionType === "removeFlag") {
                setIsBlur(true);
                setShowSaveModal(true);
                setActionData(ids);
            }
        }
    };

    const handleBulkUnflag = async (data) => {
        try {
            setIsLoading(true);
            await unFlagQuestionForBulk(data);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleBulkPublishUnpublish = async (publish, data) => {
        try {
            setIsLoading(true);
            await updateBulkQuestionStatus(publish, data);
            setStatus((prevStatus) => !prevStatus);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleFlagComment = async () => {
        try {
            const isBulk = Array.isArray(actionData?.questionIds);
            const questionIds = isBulk
                ? actionData.questionIds
                : [actionData._id];
            const data = isBulk
                ? { ids: questionIds, text: flagComment, } : { ids: questionIds, text: flagComment, };
            setIsLoading(true);
            isBulk ? await addFlagForBulkForQuestion(data, true) : await addFlag(data, true);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleRemoveFlag = async () => {
        try {
            const action = actions.find((action) => action.name === "removeFlag");
            if (action) {
                const data = {
                    ids: [action.data._id]
                }
                await removeFlag(data);
                refreshDatatable();
            }
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const actions = [
        {
            Label: "Flag",
            name: "flag",
            clickAction: () => handleActions("flagInappropriate"),
            access: flaggingAccess[0]?.add,
        }, {
            Label: "Comments",
            name: "displayComments",
            clickAction: handleDisplayComments,
            access: commentsAccess[0]?.view,
        }, {
            Label: "Remove Flag",
            name: "removeFlag",
            clickAction: () => handleRemoveFlag(),
            access: flaggingAccess[0]?.delete,
        }, {
            Label: "publish/unpublish",
            name: "publishStatus",
            clickAction: handlePublishStatus,
            access: publishAccess[0] || unpublishAccess[0],
            publishAccess: publishAccess[0],
            unPublishAccess: unpublishAccess[0],
        }, {
            Label: "Edit Question",
            name: "editQuestion",
            clickAction: () => handleActions("editQuestion"),
            access: questionsAccess[0]?.edit,
        }, {
            Label: "Delete",
            name: "delete",
            class: "text-delete",
            clickAction: handleDelete,
            access: questionsAccess[0]?.delete,
        },
    ];

    const inActivateOptions = [{
        Label: "Reactivate",
        name: "reactivate",
        clickAction: handleReactivate,
        access: publishAccess[0],
    }];

    const BulkActions = [
        {
            Label: "Publish All",
            name: "publish",
            clickAction: () => handleBulkActions("publish"),
            access: publishAccess[0]
        }, {
            Label: "Unpublish All",
            name: "unpublish",
            clickAction: () => handleBulkActions("unpublish"),
            access: unpublishAccess[0]
        }, {
            Label: "Flag All",
            name: "flag",
            clickAction: (() => handleBulkActions("flag")),
            access: flaggingAccess[0]?.add,
        }, {
            Label: "Unflag All",
            name: "removeFlag",
            clickAction: () => handleBulkActions("removeFlag"),
            access: flaggingAccess[0]?.delete,
        }, {
            Label: "Delete All",
            name: "delete",
            class: "text-delete",
            clickAction: () => handleBulkActions("delete"),
            access: questionsAccess[0]?.delete
        },
    ];

    const updateFilter = (updates) => {
        const updatedFilter = filter.reduce((acc, f) => {
            if (!updates.some((update) => update.name === f.name)) acc.push(f);
            return acc;
        }, updates);
        setFilter(updatedFilter);
    };

    const handlePageChange = (currentPage, itemsPerPage) => {
        setCurrentPage(currentPage);
        setGoToPage(-1);
        setIsLoading(true);
        const pageUpdate = [
            { name: "page", value: currentPage },
            { name: "limit", value: itemsPerPage },
        ];
        updateFilter(pageUpdate);
    };

    const extractFields = (obj) => {
        const {
            _id,
            skill,
            qtype,
            question,
            code,
            isFlag,
            status = "",
            organization,
            program,
            difficulty,
            qtype: questionType,
        } = obj;
        const encodedFormData = encodeURIComponent(_id);
        const encodedSkillName = encodeURIComponent(skill);
        const linkURL = `/question-preview?question-id=${encodedFormData}&skill-skillName=${encodedSkillName}&isQuestionsPage=${isQuestionsPage}&page=${currentPage}&filter=${encodedFilter}&skillId=${id}`;

        const questionText =
            qtype === 24
                ? "Reading Comprehension"
                : typeof question?.text === "string"
                    ? parse(question.text)
                    : "Image Question";
        const handleLinkClick = (e) => {
            e.preventDefault();
            setModalUrl(linkURL);
            setIsEdit(false);
            setIsModalOpen(true);
        };
        const popupLink = (
            <a href="#" onClick={handleLinkClick} style={{
                color: "blue", textDecoration: "underline", cursor: "pointer",
            }}>{questionText}</a>
        );
        return {
            code,
            isFlag,
            question: popupLink,
            skill,
            status,
            _id,
            organization,
            program,
            difficulty,
            qtype: questionType,
            questionTitle: questionText,
        };
    };

    const refreshDatatable = _.debounce(async () => {
        setIsLoading(true);
        try {
            const updatedFilter = [
                ...filter.filter(f => f.name !== "active"),
                { name: "active", value: dataFilterStatus }
            ];
            if (id) {
                const skillFilter = updatedFilter.find((f) => f.name === "skillIds")
                if (skillFilter) {
                    const data = id.split();
                    skillFilter.value = data
                }
            }
            const data = await getAllQuestions(updatedFilter);
            const { questions, totalPages } = data;
            setTableData(questions.map(extractFields));
            setTotalPageCount(totalPages);
            if (questions && questions.length >= 0) setDataFetched(true);
            id && filterKey ? navigate(`/curriculum/questions/${currentPage}/${id}/${filterKey}`) : navigate(`/curriculum/questions/${currentPage}`);
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsLoading(false);
            setIsBlur(false);
        }
    }, 1000);

    const closeModal = (modal) => {
        setIsBlur(false);
        modal(false);
    };

    return (
        <>
            <>
                <Title titleContent="Questions" />
                <div className={`curriculum-all-topics ${isBlur ? "blur" : ""}`}>
                    {dataFetched ? (
                        <>
                            <TempFilterHeader
                                filter={filter}
                                pageName="Questions"
                                setFilter={setFilter}
                                setIsBlur={setIsBlur}
                                showMoreFilters={true}
                                updateFilter={updateFilter}
                                setIsLoading={setIsLoading}
                                handleMoreFilter={() => {
                                    setIsBlur(true);
                                    setShowMoreFilters(true);
                                }}
                                dataFilterStatus={dataFilterStatus}
                                setShowExportModal={setShowExportModal}
                                setDataFilterStatus={setDataFilterStatus}
                                moreFiltersDropdown={moreFiltersDropdown}
                                filtersDropdown={filtersDropdownStructure}
                                setGoToPage={setGoToPage}
                                isPageChange={goToPage}
                                isHavingExportAccess={isHavingExportAccess}
                            />
                            <Suspense>
                                <DataTable
                                    columns={columns}
                                    data={tableData}
                                    actions={actions}
                                    BulkActionOptions={BulkActions}
                                    totalCount={totalPageCount}
                                    filter={filter}
                                    setFilter={setFilter}
                                    dataStatus={true}
                                    setGoToPage={setGoToPage}
                                    handlePageChange={handlePageChange}
                                    inActivateOptions={inActivateOptions}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Suspense>
                            {deleteModel && (
                                <DeleteModal
                                    showModal={deleteModel}
                                    role={deleteRole}
                                    onClose={() => closeModal(showDeleteModel)}
                                    onSave={refreshDatatable}
                                    deleteData={actionData}
                                    onClick={handleDeleteQuestion}
                                />
                            )}
                            {reactivateModel && (
                                <ReactivateModal
                                    showModal={reactivateModel}
                                    role={reactivateRole}
                                    onClose={() =>
                                        closeModal(showReactivateModel)
                                    }
                                    reactivateData={actionData}
                                    onClick={handleReactivateAction}
                                />
                            )}
                            {displayComments && (
                                <QuestionCommentsModal
                                    show={displayComments}
                                    handleClose={() =>
                                        closeModal(showDisplayComments)
                                    }
                                    intitialData={actionData}
                                    questionId={questionId}
                                    onSave={refreshDatatable}
                                    access={commentsAccess[0]?.add}
                                />
                            )}
                            {showFlagInAppropriate && (
                                <FlagInappropriateModal
                                    showModal={showFlagInAppropriate}
                                    onClose={() =>
                                        setShowFlagInAppropriate(false)
                                    }
                                    onSave={handleFlagComment}
                                    flagComment={flagComment}
                                    setFlagComment={setFlagComment}
                                    flagText="question"
                                />
                            )}
                            {showMoreFilters && (
                                <MoreFilters
                                    filter={filter}
                                    setFilter={setFilter}
                                    pageName={"Questions"}
                                    show={showMoreFilters}
                                    updateFilter={updateFilter}
                                    dataFilterStatus={dataFilterStatus}
                                    moreFiltersDropdown={moreFiltersDropdown}
                                    handleClose={() => closeModal(setShowMoreFilters)}
                                />
                            )}
                            {showSaveModal && (
                                <SaveModal
                                    showModal={showSaveModal}
                                    onClose={() => setShowSaveModal(false)}
                                    onSave={() => handleBulkUnflag(actionData)}
                                    setModalContent={true}
                                />
                            )}
                        </>
                    ) : (
                        !isLoading && (
                            <div className="empty-Container">
                                <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto", }} />
                                <b><p className="text-center" style={{ color: "green" }}>
                                    You haven't created any Questions yet.!</p></b>
                            </div>
                        )
                    )}
                </div>
                {isLoading && (
                    <div className="loader-overlay">
                        <div className="spinner">
                            <Loader />
                        </div>
                    </div>
                )}
            </>
            {isModalOpen && (
                <Suspense>
                    <PreviewModal
                        show={isModalOpen}
                        onClose={refreshDatatable}
                        url={modalUrl}
                        isEdit={isEdit}
                        setIsModalOpen={setIsModalOpen}
                        setShouldShowArrow={setShouldShowArrow}
                        shouldShowArrow={shouldShowArrow}
                    />
                </Suspense>
            )}
            {showExportModal && (
                <ExportModal
                    title="Questions"
                    filter={filter}
                    exportColumns={exportColumns}
                    handleClose={() => closeModal(setShowExportModal)}
                />
            )}
        </>
    );
}

export default CurriculumAllQuestions;
