import { BsThreeDots } from 'react-icons/bs';
import React, { useState, useRef, useEffect } from 'react';
import {
    DataTableWrapper,
    TableLayout,
    PaginatorWrapper,
    PaginatorLayout,
    MenuIcon,
    PageFilterWrapper,
    Flag
} from './DataTable.styled';
import 'react-toastify/dist/ReactToastify.css';
import { FaCaretDown } from 'react-icons/fa';
import flag from "../../Assests/Images/flag.svg";
import { useParams } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Select from 'react-select'
import { useCallback } from 'react';
import _debounce from "lodash/debounce";

/**
* DataTable Component
*
* It is the common component used wherever table required
*
* @param { columns } props -These are table headers
* @param { data } props - These are table data which should be in multidimensional
* @param { actions } props - These are the menu which appears as a dropdown .
*  It should be two dimesional array ['url','name of action'] need to navigate.
* @param { itemsPerPage } - No.of rows that need to be visible in a page. 
*
* @author - Lakshmi
*/
const DataTable = ({ columns,
    data,
    actions,
    totalCount,
    BulkActionOptions,
    filter,
    setFilter,
    inActivateOptions,
    dataStatus,
    handlePageChange,
    showCheckbox = true,
    setGoToPage,
    currentPage,
    setCurrentPage
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const currentPageData = data;
    const [showBulkActions, setShowBulkActions] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [status, setStatus] = useState(true);
    const { page } = useParams();
    const [isOrganizationPage, setIsOrganizationPage] = useState(false)
    const [isRolesPage, setIsRolesPage] = useState(false)
    const [isUsersPage, setIsUsersPage] = useState(false)
    const [isTeamsPage, setIsTeamsPage] = useState(false)

    const pathName = window.location.pathname;
    useEffect(() => {
        setIsOrganizationPage(pathName.includes("/organization"));
        setIsRolesPage(pathName.includes("/roles"));
        setIsTeamsPage(pathName.includes("/teams"))
        setIsUsersPage(pathName.includes("/users"))
    }, [pathName])

    useEffect(() => {
        filter.forEach(eachfilter => {
            if (eachfilter.name === "limit") {
                setItemsPerPage(eachfilter.value);
            }
            if (eachfilter.name === "active") {
                setStatus(eachfilter.value);
            }
        });
        setCurrentPage(1);
    }, [filter]);

    useEffect(() => {
        setCurrentPage(page ? parseInt(page) : 1);
    }, [page]);

    const handleRowSelection = (rowId, rowData) => {
        if (isOrganizationPage || isRolesPage || isTeamsPage || isUsersPage) {
            return;
        }
        setSelectedRows(prevSelectedRows => {
            if (prevSelectedRows.some(row => row.id === rowId)) {
                return prevSelectedRows.filter(row => row.id !== rowId);
            } else {
                return [...prevSelectedRows, { id: rowId, data: rowData }];
            }
        });
    };



    const handleSelectAll = (e) => {
        if (isOrganizationPage || isRolesPage || isTeamsPage || isUsersPage) {
            return;
        }
        if (selectedRows.length === currentPageData.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(currentPageData.map(item => ({ id: item._id, data: item })));
        }
    };

    const isChecked = (rowId) => selectedRows.some(row => row.id === rowId);
    useEffect(() => {
        setSelectedRows([]);
    }, [itemsPerPage, currentPageData]);

    useEffect(() => {
        setShowBulkActions(selectedRows.length > 0);
    }, [selectedRows]);

    return (
        <DataTableWrapper key={currentPage}>
            <TableLayout>
                <table className="tableParent borderless">
                    <thead className="tableHeader">
                        <tr>
                            <th scope="col">
                                <div className="multi-checkbox">
                                    {showCheckbox && <input
                                        className="form-check-input input-check-head"
                                        type="checkbox"
                                        checked={selectedRows.length === currentPageData.length && selectedRows.length > 0}
                                        onChange={(e) => !isOrganizationPage && !isRolesPage && !isTeamsPage && !isUsersPage && handleSelectAll(e)}
                                    />}
                                    {showBulkActions && status && BulkActionOptions &&
                                        <BulkActionsMenu options={BulkActionOptions}
                                            data={selectedRows} />}
                                </div>
                            </th>
                            {columns.map((eachColumn, index) => (
                                <th key={index} scope="col">
                                    {eachColumn}
                                </th>
                            ))}
                            {dataStatus && <th>Status</th>}
                            {actions.length > 0 && actions.some(action => action.access) && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData.length === 0 ? (
                            <tr>
                                <td colSpan={columns.length + 4} style={{ textAlign: "center" }}>No data available in table</td>
                            </tr>
                        ) : (
                            currentPageData.map((item, rowIndex) => (
                                <tr key={`${currentPage}-${rowIndex}-${item._id}`}>
                                    <td>
                                        <div className="form-check">
                                            {showCheckbox && (<input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={isChecked(item._id)}
                                                onChange={() => !isOrganizationPage && !isRolesPage && handleRowSelection(item._id, item)}
                                            />)}
                                        </div>
                                    </td>
                                    {Object.entries(item).map(([key, value], index) => {
                                        if (index < columns.length + 1) {
                                            if (index < columns.length) {
                                                const adjustedIndex = item.isFlag ? index : index + 1;
                                                if (adjustedIndex === 2) {
                                                    return value && value.length > 29 ? (
                                                        <td key={key} data-tooltip={value} className=''>
                                                            {value}
                                                        </td>
                                                    ) : (
                                                        <td key={key}>{value}</td>
                                                    );
                                                } else if (adjustedIndex === 1 && item.isFlag) {
                                                    return item.isFlag ? (
                                                        <td key={key} className="flag-column">
                                                            <Flag src={flag} alt="Flagged" className="flag-icon" />
                                                        </td>
                                                    ) : (
                                                        <td key={key}></td>
                                                    );
                                                }
                                                return value && value.length > 29 ? (
                                                    <td key={key} data-tooltip={value} className=''>
                                                        {value}
                                                    </td>
                                                ) : (
                                                    <td key={key}>{value}</td>
                                                );
                                            }
                                            if (dataStatus) {
                                                let badgeClass = '';
                                                let badgeText = '';
                                                switch (value) {
                                                    case 1:
                                                        badgeClass = 'bg-success';
                                                        badgeText = 'Published';
                                                        break;
                                                    case -1:
                                                        badgeClass = 'bg-danger';
                                                        badgeText = 'Unpublished';
                                                        break;
                                                    case 0:
                                                        badgeClass = 'bg-secondary';
                                                        badgeText = 'In review';
                                                        break;
                                                    case 2:
                                                        badgeClass = 'bg-warning text-dark';
                                                        badgeText = 'Picture pending';
                                                        break;
                                                    default:
                                                        badgeClass = 'bg-secondary';
                                                        badgeText = 'Unknown';
                                                }
                                                return (
                                                    <td key={key}>
                                                        <span className={`badge rounded-pill ${badgeClass}`}>{badgeText}</span>
                                                    </td>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                    {actions.some(action => action.access) && (
                                        <td>
                                            {actions.length > 0 && (
                                                <MenuDropDown
                                                    actions={actions}
                                                    data-testid="menu-dropdown"
                                                    rowIndex={item._id}
                                                    dataLength={data.length}
                                                    data={item}
                                                    status={status}
                                                    inActivateOptions={inActivateOptions ?? []}
                                                />
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </TableLayout>
            <PaginatorLayout>
                <PageFilter itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange} />
                <SecondPaginate currentPage={currentPage}
                    totalPages={totalCount}
                    onPageChange={handlePageChange}
                    itemsPerPage={itemsPerPage} />
                <Pagination currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalCount}
                    onPageChange={handlePageChange}
                    setGoToPage={setGoToPage}
                    filter={filter}
                    setFilter={setFilter}
                />
            </PaginatorLayout>
        </DataTableWrapper>
    );
};

const Pagination = ({ currentPage, setCurrentPage, totalPages, setGoToPage, filter, setFilter }) => {
    const timeoutRef = useRef(null);
    const [goToPage, setGoToPageInput] = useState(currentPage);
    const handleSearchInput = (event) => {
        let value = event.target.value ? parseInt(event.target.value, 10) : 1;
        if (isNaN(value) || value < 1) {
            value = 1;
        } else if (value > totalPages) {
            value = totalPages;
        }
        setGoToPageInput(value);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setCurrentPage(value);
            setGoToPage(value);

            const updatedFilter = filter
                .filter((f) => f.name !== "page")
                .concat({ name: "page", value });

            setFilter(updatedFilter);
        }, 1500);
    };
    const totalPageCount = totalPages === 0 ? 1 : totalPages;
    return (
        <div className='pageCount'>
            <div style={{ fontWeight: "600", color: "#000" }}>
                <div className="input-search-container">
                    <div className="input-search-wrapper">
                        <span style={{ marginRight: '16px' }}>Page</span>
                        <input
                            className="input-search"
                            type="number"
                            placeholder={currentPage}
                            min="1"
                            max={totalPages}
                            onChange={handleSearchInput}
                            value={goToPage}
                        />
                    </div>
                </div>
            </div>
            of {totalPageCount}
        </div>
    );
};

const SecondPaginate = ({ currentPage, totalPages, onPageChange, itemsPerPage }) => {
    const generatePageNumbers = () => {
        const visiblePages = 10;
        const pageNumbers = [];

        if (currentPage > 1) {
            pageNumbers.push("< Previous");
        }

        let startPage;
        if (totalPages - currentPage <= Math.floor(visiblePages / 2)) {
            startPage = Math.max(totalPages - visiblePages + 1, 1);
        } else {
            startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
        }
        const endPage = Math.min(startPage + visiblePages - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (totalPages > endPage) {
            pageNumbers.push("...");
            pageNumbers.push(totalPages);
        }

        if (currentPage < totalPages) {
            pageNumbers.push("Next >");
        }

        return pageNumbers;
    };

    const pageNumbers = generatePageNumbers();

    const handlePageButtonClick = (pageNumber) => {
        if (pageNumber === '< Previous') {
            onPageChange(currentPage - 1, itemsPerPage);
        } else if (pageNumber === 'Next >') {
            onPageChange(currentPage + 1, itemsPerPage);
        } else {
            onPageChange(pageNumber, itemsPerPage);
        }
    };

    return (
        <PaginatorWrapper>
            {pageNumbers.map((pageNumber, index) => (
                <button
                    className={`page-item${pageNumber === "< Previous" || pageNumber === "Next >"
                        ? " bold"
                        : ""
                        }`}
                    key={index}
                    onClick={() => handlePageButtonClick(pageNumber)}
                    style={{
                        backgroundColor:
                            pageNumber === currentPage
                                ? "rgba(52, 123, 250, 0.10)"
                                : "transparent",
                        color: pageNumber === currentPage ? "#347BFA" : "#6C6C6C",
                    }}
                    disabled={pageNumber === currentPage}
                >
                    {pageNumber}
                </button>
            ))}
        </PaginatorWrapper>
    );
};

const PageFilter = ({ itemsPerPage, onPageChange }) => {
    const handlePageChange = (selectedOption) => {
        const newItemsPerPage = selectedOption.value;
        onPageChange(1, newItemsPerPage);
    };

    const options = [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
    ]
    return (
        <PageFilterWrapper>
            <span>No. of entries: </span>
            <Select
                className="filter-dropdowns"
                value={options.find(option => option.value === itemsPerPage)}
                onChange={handlePageChange}
                options={options}
                menuPlacement="auto"
                styles={{
                    menu: (base) => ({
                        ...base,
                        marginTop: '1rem',
                        zIndex: 9999,
                        width: "2.7rem",
                        minHeight: '0rem',
                        color: '#000'
                    }),
                    valueContainer: (provided, state) => ({
                        ...provided,
                        paddingLeft: '0.1rem',
                        padding: '0rem',
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        background: '#fff',
                        borderColor: '#DFE3EB',
                        minHeight: '0.45rem !important',
                        height: '1.65rem',
                    }),
                    indicatorSeparator: state => ({
                        display: 'none',
                    }),
                    indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: '25px',
                        padding: '0rem',
                        paddingBottom: '0.1rem',
                        color: '#000'
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        height: '1.5rem'
                    }),
                }} />

        </PageFilterWrapper>
    );
};

const MenuDropDown = ({ actions, rowIndex, dataLength, data, status, inActivateOptions = [] }) => {
    const [dropdownStates, setDropdownStates] = useState(Array(dataLength).fill(false));
    const [updatedActions, setUpdatedActions] = useState(actions);
    const menuRef = useRef(null);
    const STATUS = {
        UNPUBLISH: -1,
        IN_REVIEW: 0,
        PUBLISH: 1,
        PICTURE_PENDING: 2,
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setDropdownStates(Array(dataLength).fill(false));
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const handleMenuDropDown = (index) => {
        if (status) {
            const updatedActionsCopy = updatedActions.filter(action => action.access);
            updatedActionsCopy.forEach(action => {
                if (action.name === 'publishStatus') {
                    if (action.unPublishAccess && data.status === STATUS.PUBLISH) {
                        action.Label = data.status === STATUS.PUBLISH ? "Unpublish" : "";
                    } else if (action.publishAccess && data.status === STATUS.UNPUBLISH) {
                        action.Label = data.status === STATUS.UNPUBLISH ? "Publish" : "";
                    } else if (action.publishAccess && data.status === STATUS.IN_REVIEW) {
                        action.Label = "Publish";
                    } else {
                        action.Label = '';
                    }
                }
                action.data = data;
            });
            setUpdatedActions(updatedActionsCopy);

            if (!data.isFlag) {
                setUpdatedActions(updatedActionsCopy.filter(action => action.name !== 'removeFlag'));
            } else {
                const updatedActionsCopy = actions.filter(action => action.access);
                updatedActionsCopy.forEach(eachAction => {
                    if (eachAction.name === 'publishStatus') {
                        if (eachAction.unPublishAccess && data.status === STATUS.PUBLISH) {
                            eachAction.Label = data.status === STATUS.PUBLISH ? "Unpublish" : "";
                        } else if (eachAction.publishAccess && data.status === STATUS.UNPUBLISH) {
                            eachAction.Label = data.status === STATUS.UNPUBLISH ? "Publish" : "";
                        } else if (eachAction.publishAccess && data.status === STATUS.IN_REVIEW) {
                            eachAction.Label = "Publish";
                        } else {
                            eachAction.Label = "";
                        }
                    }
                    eachAction.data = data;
                });
                setUpdatedActions(updatedActionsCopy);
            }
        } else {
            inActivateOptions.map(action => {
                action.data = data;
            })
        }

        const newDropdownStates = [...dropdownStates];
        newDropdownStates[index] = !newDropdownStates[index];
        setDropdownStates(newDropdownStates);
    };

    const handleActionClick = (action) => {
        action.clickAction();
        setDropdownStates(Array(dataLength).fill(false));
    }
    const anyActionHasAccess = actions.some(action => action.access);

    return (
        <div>
            <MenuIcon>
                <div className="meatballs-menu" ref={menuRef}>
                    <div className="menu-toggle" onClick={() => {
                        if (status) {
                            handleMenuDropDown(rowIndex);
                        } else {
                            if (inActivateOptions?.length) {
                                handleMenuDropDown(rowIndex);
                            }
                        }
                    }}>
                        <BsThreeDots />
                    </div>
                    {dropdownStates[rowIndex] && anyActionHasAccess && (
                        <div className="menu-options">
                            {status ? (
                                updatedActions.map((action, index) => (
                                    action.access && action.Label !== '' &&
                                    <React.Fragment key={index}>
                                        <div
                                            className={`menu-option ${action.class ? action.class : ''}`}
                                            onClick={() => handleActionClick(action)}
                                        >
                                            {action.Label}
                                        </div>
                                        {index === updatedActions.length - 2 && <div className="last-option"></div>}
                                    </React.Fragment>
                                ))
                            ) : (
                                inActivateOptions.map((action, index) => (
                                    action.access &&
                                    <React.Fragment key={index}>
                                        <div
                                            className={`menu-option ${action.class ? action.class : ''}`}
                                            onClick={() => handleActionClick(action)}
                                        >
                                            {action.Label}
                                        </div>
                                        {index === inActivateOptions.length - 2 && <div className="last-option"></div>}
                                    </React.Fragment>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </MenuIcon>
        </div>
    );
};
const BulkActionsMenu = ({ options, data, closeMenuAction }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const menuRef = useRef(null);
    const [updatedOptions, setUpdatedOptions] = useState(options);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = async () => {
        const updatedActionsCopy = options.filter(action => action.access);
        updatedActionsCopy.forEach(action => {
            action.data = data;
        });
        await setUpdatedOptions(updatedActionsCopy);
        setShowDropdown(!showDropdown);
    };

    const handleActionClick = (action) => {
        toggleDropdown();
        action.clickAction();
        if (closeMenuAction) {
            closeMenuAction();
        }
    };

    const anyActionHasAccess = options.some(action => action.access);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            You are not having access
        </Tooltip>
    );

    return (
        <>
            <div>
                {anyActionHasAccess ? (
                    <div className="down-arrow" onClick={toggleDropdown} ref={menuRef}>
                        <FaCaretDown />
                    </div>
                ) : (
                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <div className="down-arrow" ref={menuRef}>
                            <FaCaretDown />
                        </div>
                    </OverlayTrigger>
                )}
                {showDropdown && (
                    <div ref={menuRef} className="bulk-dropdown" data-toggle="modal">
                        {updatedOptions.map((eachOption, index) => (
                            eachOption.access &&
                            <React.Fragment key={index}>
                                <div
                                    className={`bulk-dropdown-option ${eachOption.class ? eachOption.class : ''} ${updatedOptions.length > 1 && index === updatedOptions.length - 1 ? 'bulk-last-child' : ''}`}
                                    onClick={() => handleActionClick(eachOption)}
                                >
                                    {eachOption.Label}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

DataTable.propTypes = {};

DataTable.defaultProps = {};

export default React.memo(DataTable);
