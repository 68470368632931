export const baseUrl = process.env.REACT_APP_BASE_URL;

export const programCodeUrl = `${baseUrl}/cms/v1/getProgramCodes/`;

export const domainCodesUrl = `${baseUrl}/cms/v1/getDomainCodes/`;
export const domainTitlesUrl = `${baseUrl}/cms/v2/getDomainTitles/`

export const levelCodesUrl = `${baseUrl}/cms/v1/getLevelCodes/`;
export const levelTitlesUrl = `${baseUrl}/cms/v2/getLevelTitles/`;

export const topicCodesUrl = `${baseUrl}/cms/v1/getTopicCodes/`;
export const topicTitlesUrl = `${baseUrl}/cms/v2/getTopicTitles/`;

export const organizationTitlesUrl = `${baseUrl}/cms/v1/getAllOrganizationTitles/`;
export const getProgramUrl = `${baseUrl}/cms/v2/getProgramTitles/`;
export const getLevelUrl = `${baseUrl}/cms/v2/getLevelTitles/`;
export const getDomainUrl = `${baseUrl}/cms/v2/getDomainTitles/`;
export const getTopicUrl = `${baseUrl}/cms/v2/getTopicTitles/`;
export const getSkillUrl = `${baseUrl}/cms/v2/getSkillTitles/`;
export const getRolesUrl = `${baseUrl}/cms/v1/getRoleAndTemplateTitles/`;

export const fetchToken = () => {
    const token = localStorage.getItem('token')
    return token;
}