import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;
var token = fetchToken();

export const questionCodeUrl = `${baseUrl}/cms/v1/getQuestionCodes/`;

export const buildQueryParam = (filter) => {
    token = fetchToken();
    if (Array.isArray(filter.value) && filter.value.length > 0) {
        const paramName = filter.name;
        const paramValues = filter.value.map(value =>
            paramName === "title" || paramName === "code" || paramName === "skillIds" || paramName === "organizationIds"
                || paramName === "difficulty" || paramName === "limit" || paramName === "levelIds"
                || paramName === "programIds" || paramName === "domainIds" || paramName === "topicIds"
                || paramName === "flaggedBy"
                ? `${paramName}[]=${value}` : `${paramName}[]=${value.id}`
        );
        return paramValues.join("&");
    }
    return `${filter.name}=${filter.value}`;
};

export const getAllQuestions = async (filterData) => {
    const filterParams = filterData.map(buildQueryParam).join("&");
    const url = `${baseUrl}/cms/v2/getAllQuestions?${filterParams}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch questions.");
            return { questions: [], totalPages: 0 };
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getQuestionCodes = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch questions.");
            return { questions: [], totalPages: 0 };
        }
        if (result.success) {
            const questionCodes = result.data.questionCodes.map((code) => ({
                value: code.code,
                label: code.code,
                id: code._id,
            }));
            return questionCodes;
        }
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const deleteQuestion = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/deleteQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Question deleted successfully.");
        return result;
    } catch (error) {
        throw new Error(error);
    }
}

export const reactivateQuestion = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/reactivateQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Question reactiavted successfully.");
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getAllCommentsForQuestions = async (questionId, isFlagged) => {
    try {
        let url = `${baseUrl}/cms/v1/getAllQuestionComments/${questionId}`;
        url += isFlagged ? `?flag=${isFlagged}` : "";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            throw new Error(`Failed to fetch comments: ${result.statusText}`);
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const addCommentToQuestion = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/addCommentToQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(response.message);
            return false;
        }
        toast.success("Comment added successfully");
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const addFlag = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v2/addCommentToQuestion?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flag added successfully.");
        return result;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
};

export const addFlagForBulkForQuestion = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v2/addCommentToQuestion?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flag added successfully.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
};

export const removeFlag = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/questionUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flag removed successfully.");
        return ;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const unFlagQuestionForBulk = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/questionUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flag removed successfully.");
        return true;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const updateBulkQuestionStatus = async (publish, data) => {
    try {
        let url = publish
            ? `${baseUrl}/cms/v1/publishQuestion`
            : `${baseUrl}/cms/v1/unPublishQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Status updated successfully.");
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getQuestionById = async (id) => {
    const url = `${baseUrl}/cms/v2/getQuestionById/${id}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch question.");
            return null;
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const publishQuestion = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/publishQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Question published successfully.");
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const unPublishQuestions = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/unPublishQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Question unpublished successfully.");
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};
