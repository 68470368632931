/**
 * Returns the current date and time formatted as 'dd/mm/yyyy_hh:mm:ss'.
 * The date and time are formatted according to the 'en-GB' locale.
 * The underscore (_) separates the date and time components.
 */
export const getCurrentDateTimeFormatted = () => {
    const now = new Date();
    const date = now.toLocaleDateString("en-GB");
    const time = now.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    return `${date}_${time.replace(/:/g, ":")}`;
};
