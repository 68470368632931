import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { Dropdown, Row, Col } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useCallback, useState } from "react";

import { debounce } from 'lodash';
import SideTable from "./SideTable";
import FlagModal from "../Modals/FlagModal";
import { FaCaretDown } from 'react-icons/fa';
import Loader from "Components/Loader/Loader";
import flag from "../../../Assests/Images/flag.svg";
import CommentsModal from "../Modals/CommentsModal";
import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import ReactivateModal from "../Modals/ReactivateModal/ReactivateModal";
import BulkImportModal from "../Modals/BulkImportModal/BulkImportModal";
import { CustomPagination } from '../../../Components/DataTable/CustomPagination';
import { statusCountAction } from '../../../redux/slices/SpellAndVocab/statusSlice';
import ValidationErrorModal from "../Modals/ValidationErrorModal/ValidationErrorModal";
import { openCommentsModal } from '../../../redux/slices/ModalSlice/CommentsModalSlice';
import { fetchAllSpellings } from "../../../redux/slices/SpellAndVocab/Spelling/spellingSlice";
import { publishUnpublishActions } from "../../../redux/slices/DatatableActionSlices/publishUnpublishActionSlice";
import { openDeleteModal, passDeleteItemData } from "../../../redux/slices/DatatableActionSlices/deleteActionSlice";
import {
    passFlagUnflagItemData,
    openFlagModal,
    flagUnflagActions
} from '../../../redux/slices/DatatableActionSlices/flagUnflagSlice';
import {
    openReactivateModal,
    passReactivateItemData
} from "../../../redux/slices/DatatableActionSlices/reactivateActionSlice";
import {
    getAllCommentsForSpelling,
    addCommentsToSpelling
} from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";

const customStyles = { rows: { style: { height: '3.375rem' } } };

function Spelling() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { isModalOpen } = useSelector((state) => state.fileUpload);
    const { isCommentModal } = useSelector((state) => state.comments);
    const { showFlagModal } = useSelector((state) => state.flagUnflag);
    const { spellings, loading } = useSelector((state) => state.spelling);
    const { isDeleteModalOpen } = useSelector((state) => state.delete || {});
    const { activeProgramDetails } = useSelector((state) => state.globalData);
    const { isReactivateModalOpen } = useSelector((state) => state.reactivate);
    const { isErrorModalOpen } = useSelector((state) => state.validationErrorModal);
    const { currentSelectedLevel, totalQuestionCount, title } = useSelector((state) => state.level);
    const { currentStatus, isActive, searchKey, statusCountArray } = useSelector((state) => state.status);

    const [rowId, setRowId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isClosing, setIsClosing] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]);
    const [actionOptions, setActionOptions] = useState([]);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [triggerClearSelectRow, setTriggerClearSelectRow] = useState(0);

    const activeProgramId = activeProgramDetails?._id;

    useEffect(() => {
        if (triggerClearSelectRow > 0) {
            setClearSelectedRows(true);
            setSelectedRows([]);
        }
    }, [triggerClearSelectRow]);

    useEffect(() => {
        fetchTableData();
        setSelectedRows([]);
        var options = actions(isActive);
        setActionOptions(options)
        return () => fetchTableData.cancel();
    }, [dispatch, currentPage, rowsPerPage, currentSelectedLevel, searchKey, isActive, currentStatus, statusCountArray]);

    const fetchTableData = (debounce(() => {
        if (currentSelectedLevel) {
            dispatch(fetchAllSpellings({
                getApiEndPoint: 'getSpellingQuestionsByLevel',
                status: currentStatus,
                active: isActive,
                levelId: currentSelectedLevel,
                searchKey: searchKey,
                page: currentPage,
                limit: rowsPerPage
            }));
        }
    }, 1000));

    const actions = (isActive) => {
        if (!isActive) {
            return [
                { label: 'Reactivate', action: 'reactivate' }
            ];
        }
        return [
            { label: 'Edit', action: 'edit' },
            {
                label: row => row.status === 1 ? 'Unpublish' : 'Publish',
                action: row => row.status === 1 ? 'unpublish' : 'publish'
            },
            {
                label: row => row.isFlag ? 'Remove Flag' : 'Flag',
                action: row => row.isFlag ? 'unflag' : 'flag'
            },
            { label: 'Comments', action: 'comments' },
            { label: 'Delete', action: 'delete' }
        ];
    };

    const bulkActions = [
        { label: "Publish All", action: "publish" },
        { label: "Unpublish All", action: "unpublish" },
        { label: "Flag All", action: "flag" },
        { label: "Unflag All", action: "unflag" },
        { label: "Delete All", action: "delete" },
        // { label: "Reactivate All", action: "reactivate" }
    ];

    const getBulkActions = (status) => {
        if (status === null || typeof status !== 'number') {
            return [];
        }
        const commonActions = bulkActions.filter(action =>
            action.action === "flag" || action.action === "unflag" || action.action === "delete"
        );
        let statusSpecificActions = [];
        if (status === 1) {
            statusSpecificActions = bulkActions.filter(action => action.action === "unpublish");
        } else if (status === -1) {
            statusSpecificActions = bulkActions.filter(action => action.action === "publish");
        } else if (status === 0) {
            statusSpecificActions = bulkActions.filter(action => action.action === "publish" || action.action === "unpublish");
        }
        return [...statusSpecificActions, ...commonActions];
    };

    const availableBulkActions = getBulkActions(currentStatus);

    const handleAction = useCallback((row, action) => async () => {
        const { _id, question } = row;
        let result = null;
        if (action === 'comments') {
            dispatch(openCommentsModal());
            setRowId(_id);
        }
        else if (action === 'publish' || action === 'unpublish') {
            result = await dispatch(publishUnpublishActions({
                status: action === 'publish' ? 1 : -1,
                ids: _id,
            }));
        }
        else if (action === 'flag') {
            dispatch(passFlagUnflagItemData({ ids: [_id], isFlag: true }));
            dispatch(openFlagModal());
        }
        else if (action === 'unflag') {
            await dispatch(flagUnflagActions({ id: _id }));
            // fetchTableData();
            await dispatch(statusCountAction(activeProgramId));

        }
        else if (action === 'edit') {
            let editUrl = `/spellandvocab/spelling-edit?question-id=${_id}&level-id=${row.parent}`;
            if (question.text) {
                editUrl += `&text=${encodeURIComponent(question.text)}`;
            }
            navigate(editUrl);
        }
        else if (action === 'delete') {
            dispatch(passDeleteItemData({
                title: 'Spelling',
                name: question?.text || 'No name',
                id: [_id],
                key: 'questionIds',
                endPoint: 'deleteQuestion',
            }));
            dispatch(openDeleteModal());
        }
        else if (action === 'reactivate') {
            dispatch(passReactivateItemData({
                title: 'Spelling',
                name: question?.text || 'No name',
                id: _id,
                key: 'questionId',
                endPoint: 'reactivateQuestion',
            }));
            dispatch(openReactivateModal());
        }
        else {
            console.error(`Unknown action: ${action}`);
        }
        if (result) {
            await dispatch(statusCountAction(activeProgramId));
        }
    }, [dispatch, navigate, activeProgramId, title]);

    const handleBulkActions = useCallback((rows, action) => async () => {
        setClearSelectedRows(false);
        const rowIds = rows.map(row => row._id);
        let result = null;
        if (action === 'publish' || action === 'unpublish') {
            result = await dispatch(publishUnpublishActions({
                status: action === 'publish' ? 1 : -1,
                ids: rowIds,
            }));
        }
        else if (action === 'flag') {
            dispatch(passFlagUnflagItemData({
                ids: rowIds, isFlag: true, isBulkAction: true
            }));
            dispatch(openFlagModal());
        }
        else if (action === 'unflag') {
            await dispatch(flagUnflagActions({ id: rowIds, isBulkAction: true }));
            fetchTableData();
        }
        else if (action === 'delete') {
            dispatch(passDeleteItemData({
                title: 'Spelling',
                id: rowIds,
                key: 'questionIds',
                endPoint: 'deleteQuestion',
            }));
            dispatch(openDeleteModal());
        }
        else {
            console.error(`Unknown action: ${action}`);
        }
        if (result) {
            await dispatch(statusCountAction(activeProgramId));
        }
        setTriggerClearSelectRow(prev => prev + 1)
    }, [dispatch, navigate, activeProgramId, title]);

    const columns = [
        {
            name: (
                <div
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', position: "absolute" }}
                >
                    {selectedRows.length > 0 && (
                        <Dropdown>
                            <Dropdown.Toggle
                                as="div"
                                id="dropdown-custom-toggle"
                                style={{ cursor: 'pointer' }}
                            >
                                {currentStatus !== 9999 && <FaCaretDown style={{ marginLeft: '0' }} />}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                {availableBulkActions.map((action, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={handleBulkActions(selectedRows, action.action)}
                                        className={index === availableBulkActions.length - 1 ? 'custom-dropdown-item-last-item'
                                            : 'custom-dropdown-item'}
                                    >
                                        {action.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            ),
            width: '5%'
        }, {
            name: "Code",
            selector: row => row.code,
            style: { textAlign: 'left' },
            width: '15%'
        }, {
            selector: row => row.isFlag,
            cell: row => (
                row.isFlag ? <img src={flag} alt="flag" /> : null
            ),
            width: '5%'

        }, {
            name: "Audio Sentence",
            selector: row => row.question.text,
            left: true,
            width: '30%',
            cell: row => (
                <div
                    style={{
                        cursor: 'pointer',
                        color: 'blue',
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%'
                    }}
                    onClick={() => {
                        const encodedLevelName = encodeURIComponent(title.trim());
                        const questionId = row._id;
                        const url = `/questionBuilder-preview?level-levelName=${encodedLevelName}` +
                            `&question-id=${questionId}&mode=preview&active-page=spelling`;

                        console.log(row._id)
                        console.log(url)
                        navigate(url);
                    }}
                >
                    {row.question.text}
                </div>
            )
        }, {
            name: "Word",
            selector: row => row.answer,
            left: true,
            width: '10%'
        }, {
            cell: row => (
                <Dropdown>
                    <Dropdown.Toggle as="div" id="dropdown-custom-toggle" style={{ cursor: 'pointer' }}>
                        <BsThreeDotsVertical style={{ cursor: 'pointer' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className="custom-dropdown-menu">
                        {actionOptions.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.action === 'delete' && <Dropdown.Divider className="custom-divider" />}
                                <Dropdown.Item
                                    onClick={handleAction(row, typeof item.action === 'function' ?
                                        item.action(row) : item.action)}
                                    className={index === actionOptions.length - 1 ?
                                        ' custom-dropdown-item-last-item' : 'custom-dropdown-item'}>
                                    {typeof item.label === 'function' ? item.label(row) : item.label}
                                </Dropdown.Item>
                            </React.Fragment>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            ),
            style: { marginRight: '1rem' },
            right: true,
            width: '30%'
        }
    ];

    const handlePageChange = (page) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        if (page !== currentPage) {
            setRowsPerPage(newPerPage);
            setCurrentPage(1);
        }
    };

    const handleBulkRowsSelection = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    };

    return (
        <>
            <Row className='mt-5 mx-0 '>
                <Col md={3} className='table-wrapper sidetable-wrapper p-0'>
                    <SideTable setCurrentPage={setCurrentPage} />
                </Col>
                <Col className=' table-wrapper p-5 offset-md-1-2 table-container'>
                    <div className="data-table-wrapper">
                        <DataTable
                            fixedHeader
                            data={spellings}
                            columns={columns}
                            persistTableHead={true}
                            progressPending={loading}
                            customStyles={customStyles}
                            paginationComponent={CustomPagination}
                            selectableRows={currentStatus !== 9999}
                            onSelectedRowsChange={handleBulkRowsSelection}
                            clearSelectedRows={clearSelectedRows}
                            progressComponent={<div style={{ padding: '24px' }}><Loader /></div>}
                        />
                    </div>
                    <div className="data-table-pagination">
                        <CustomPagination
                            currentPage={currentPage}
                            rowsPerPage={rowsPerPage}
                            rowCount={totalQuestionCount}
                            onChangePage={handlePageChange}
                            paginationPerPage={rowsPerPage}
                            paginationTotalRows={totalQuestionCount}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                        />
                    </div>
                </Col>
            </Row>
            {isModalOpen && (<div className={`modal-overlay ${isClosing ? 'fade-out' : ''}`}>
                <BulkImportModal fileTypes={['.xlsx']} endPoint={'v1/importSpelling'} />
            </div>)}
            {isErrorModalOpen && (
                <div className={`modal-overlay ${isClosing ? 'fade-out' : ''}`}>
                    <ValidationErrorModal />
                </div>
            )}
            {isCommentModal && (<CommentsModal
                show={true}
                fetchCommentsApi={getAllCommentsForSpelling}
                addCommentApi={addCommentsToSpelling}
                questionId={rowId}
            />
            )}
            {showFlagModal && (
                <div className={`modal-overlayFlag ${isClosing ? 'fade-out' : ''}`}>
                    <FlagModal flagText="content" DataTable={() => fetchTableData()} />
                </div>
            )}
            {isDeleteModalOpen && (
                <div className={`modal-overlay ${isClosing ? 'fade-out' : ''}`}><DeleteModal /></div>
            )}
            {isReactivateModalOpen && (
                <div className={`modal-overlay ${isClosing ? 'fade-out' : ''}`}><ReactivateModal /></div>
            )}
        </>
    );
}

export default Spelling;