import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../../Components/Title/Title";
import Loader from "../../../Components/Loader/Loader";
import logo from "../../../Assests/Images/empty-box.svg";
import SaveModal from "Components/Modals/SaveModal/SaveModal";
import MoreFilters from "Components/FilterHeader/MoreFilters";
import DataTable from "../../../Components/DataTable/DataTable";
import TempFilterHeader from "Components/FilterHeader/OptimizedFilterHeader";
import DeleteModal from "../../../Components/Modals/DeleteModal/DeleteModal";
import EditSkillModal from "../../../Components/Modals/Skills/EditSkillModal";
import ReactivateModal from "Components/Modals/ReactivateModal/ReactivateModal";
import SkillCommentsModal from "../../../Components/Modals/Comments/SkillCommentsModal";
import ImportExportQuestions from "../../../Components/Modals/Skills/ImportExportQuestions";
import AddViewVideoExplanation from "../../../Components/Modals/Skills/AddViewVideoExplanation";
import QuestionsBulkUploadErrorModal from "Components/Modals/Questions/QuestionsBulkUploadErrorModal";
import FlagInappropriateModal from "../../../Components/Modals/FlagInappropriateModal/FlagInappropriateModal";
import {
    addFlag,
    unflagSkill,
    deleteSkill,
    getAllSkills,
    publishSkills,
    unPublishSkills,
    reactivateSkill,
    updateBulkStatus,
    unFlagSkillForBulk,
    addFlagForBulkForSkill,
} from "../../../Utils/ApiHandler/SkillsApi";
import ExportModal from "Components/Modals/ExportModal/ExportModal";

const STATUS = {
    UNPUBLISH: -1,
    IN_REVIEW: 0,
    PUBLISH: 1,
    NO_IMAGE: 2,
};

const columns = [
    "Code",
    "",
    "Skill Name",
    "Topic",
    "Questions",
    "Description",
];

const moreFiltersDropdown = [{
    "organization": true,
    "code": true,
    "program": true,
    "level": true,
    "domain": false,
    "topic": false,
    "skill": false,
    "question": false,
    "flag": false,
    "status": false,
    "flaggedBy": false,
}];

const filtersDropdownStructure = [{
    "domain": true,
    "topic": true,
    "skill": true,
    "status": true,
    "flag": true,
    "code": false,
    "level": false,
    "program": false,
    "question": false,
    "flaggedBy": false,
    "complexity": false,
    "questionType": false,
    "organization": false,
}];

const exportColumns = [
    { key: "skillCode", label: "Skill Code" },
    { key: "skillName", label: "Skill Name" },
    { key: "programName", label: "Program Name" },
    { key: "levelName", label: "Level Name" },
    { key: "domainName", label: "Domain Name" },
    { key: "topicName", label: "Topic Name" },
    { key: "numQuestions", label: "Number of Questions" },
    { key: "status", label: "Status" },
];

function CurriculumAllSkills() {

    const { page, id, filterKey } = useParams();
    const navigate = useNavigate();

    const pageLoaded = useRef(false);

    const [isBlur, setIsBlur] = useState(false);
    const [status, setStatus] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [deleteRole, setDeleteRole] = useState("");
    const [actionData, setActionData] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [flagComment, setFlagComment] = useState("");
    const [uploadError, setUploadError] = useState("");
    const [dataFetched, setDataFetched] = useState(false);
    const [skillsAccess, setSkillAccess] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [publishAccess, setPublishAccess] = useState(false);
    const [showEditModel, setShowEditModel] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [flaggingAccess, setFlaggingAccess] = useState(false);
    const [commentsAccess, setCommentsAccess] = useState(false);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [unpublishAccess, setUnpublishAccess] = useState(false);
    const [dataFilterStatus, setDataFilterStatus] = useState(true);
    const [addQuestionAccess, setAddQuestionAccess] = useState(false);
    const [showCommentsModel, setShowCommentsModel] = useState(false);
    const [showReactiveModel, setShowReactiveModel] = useState(false);
    const [showUploadError, setShowBulkUploadError] = useState(false);
    const [showFlagInAppropriate, setShowFlagInAppropriate] = useState(false);
    const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
    const [showImportQuestionsModel, setShowImportQuestionsModel] = useState(false);
    const [showAddVideoExplanationModel, setShowAddVideoExplanationModel] = useState(false);
    const [goToPage, setGoToPage] = useState(0);
    const [filter, setFilter] = useState([
        { name: "page", value: 1 },
        { name: "limit", value: 10 },
        { name: "active", value: true },
        { name: "status", value: "" },
        { name: "search", value: "" },
        { name: "title", value: [] },
        { name: "topicIds", value: [] },
        { name: "code", value: [] },
      
    ]);
    const [isHavingExportAccess, setIsHavingExportAccess] = useState(false);

    useEffect(() => {
        if (!pageLoaded.current) {
            allowedAccess();
            pageLoaded.current = true;
        }
    }, []);

    useEffect(() => {
        if (goToPage > 1) {
            setCurrentPage(goToPage);
        }
        else if (filter) {
            setCurrentPage(1);
        }
        navigate(`/curriculum/skills/${currentPage}`);
    }, [filter, goToPage, dataFilterStatus]);

    useEffect(() => {
        refreshDatatable();
    }, [filter, status]);

    const allowedAccess = () => {
        DbConfig.curriculumAccess.toArray().then((curriculumAccessData) => {
            setIsHavingExportAccess(curriculumAccessData[0].exportCurriculum)
            const accessMapping = {
                skills: setSkillAccess,
                flagging: setFlaggingAccess,
                comments: setCommentsAccess,
                publishCurriculum: setPublishAccess,
                unPublishCurriculum: setUnpublishAccess,
                questions: setAddQuestionAccess,
            };
            for (const key in accessMapping) {
                const setterFunction = accessMapping[key];
                const accessValues = curriculumAccessData.map((item) => item[key]);
                setterFunction(accessValues);
            }
        });
    };

    const handleAddQuestion = () => {
        const action = actions.find((action) => action.name === "addQuestion");
        if (action) {
            const { title, _id } = action.data;
            const encodedSkillName = encodeURIComponent(title);
            const encodedSkillId = encodeURIComponent(_id);
            const url =
                `/curriculum/addQuestion?skillName=${encodedSkillName}&skillId=${encodedSkillId}&title=Add Question`;
            navigate(url);
        }
    };

    const handlePublishStatus = async () => {
        const publishAction = actions.find((action) => action.name === "publishStatus");
        if (publishAction) {
            const newStatus = publishAction.data.status === STATUS.PUBLISH ? STATUS.UNPUBLISH : STATUS.PUBLISH;
            const data = {
                skillId: publishAction.data._id,
                status: newStatus,
                parentId: publishAction.data.parentId,
            };
            try {
                const isCurrentlyPublished = publishAction.data.status === STATUS.PUBLISH;
                const newStatus = isCurrentlyPublished ? STATUS.UNPUBLISH : STATUS.PUBLISH;
                const data = { skillIds: [publishAction.data._id], status: newStatus, };
                if (isCurrentlyPublished) await unPublishSkills(data);
                else await publishSkills(data);
                setStatus((prevStatus) => !prevStatus);
                setIsLoading(true);
                setIsBlur(true);
                // refreshDatatable();
            } catch (error) {
                throw new Error(error);
            }
        }
    }

    const handleRemoveFlag = async () => {
        const action = actions.find((action) => action.name === "removeFlag");
        if (action) {
            const data = { id: action.data._id };
            try {
                const result = await unflagSkill(data);
                if (result.success) {
                    setIsLoading(true);
                    refreshDatatable();
                }
            } catch (error) {
                throw new Error(error);
            }
        }
    };

    const handleActions = (actionType) => {
        const actionHandler = actionsMapping[actionType];
        if (actionHandler) {
            setIsBlur(true);
            actionHandler();
        }
    };

    const actionsMapping = {
        delete: () => {
            setShowDeleteModel(true);
            const action = actions.find((action) => action.name === "delete");
            if (action) {
                setActionData([
                    {
                        title: action.data.title,
                        skillIds: [action.data._id],
                        child: action.data.child,
                    },
                ]);
                setDeleteRole("Skill");
            }
        },
        edit: () => {
            setShowEditModel(true);
            const action = actions.find((action) => action.name === "edit");
            if (action) setActionData(action.data);
        },
        flagInappropriate: () => {
            setShowFlagInAppropriate(true);
            const action = actions.find((action) => action.name === "flag");
            if (action) setActionData(action.data);
        },
        importQuestions: () => {
            setShowImportQuestionsModel(true);
            const action = actions.find((action) => action.name === "importQuestions");
            if (action) setActionData(action.data._id);
        },
        comments: () => {
            setShowCommentsModel(true);
            const action = actions.find((action) => action.name === "comments");
            if (action) setActionData(action.data);
        },
        addViewVideoExplanation: () => {
            setShowAddVideoExplanationModel(true);
            const action = actions.find((action) => action.name === "addViewVideoExplanation");
            if (action) setActionData(action.data);
        },
    };

    const processBulkAction = (name) => {
        const action = BulkActions.find((action) => action.name === name);
        if (action) {
            if (name === "removeFlag") {
                const ids = action.data.map((eachAction) => eachAction.id);
                setActionData({ ids });
                return { ids };
            } else {
                const skillIds = action.data.map((eachAction) => eachAction.id);
                setActionData({ skillIds });
                return { skillIds };
            }
        }
    };

    const handleBulkActions = (actionType) => {
        var skillIds;
        var ids;
        if (actionType === "delete") {
            skillIds = processBulkAction("delete");
            if (skillIds) {
                setIsBlur(true);
                setDeleteRole("MultipleSkills");
                setShowDeleteModel(true);
            }

        } else if (actionType === "flag") {
            skillIds = processBulkAction("flag");
            if (skillIds) {
                setIsBlur(true);
                setDeleteRole("MultipleSkills");
                setShowFlagInAppropriate(true);
            }

        } else if (actionType === "publish") {
            skillIds = processBulkAction("publish");
            if (skillIds) {
                handleBulkPublishUnpublish(true, skillIds);
            }
        } else if (actionType === "unpublish") {
            skillIds = processBulkAction("unpublish");
            if (skillIds) {
                handleBulkPublishUnpublish(false, skillIds);
            }
        } else if (actionType === "removeFlag") {
            ids = processBulkAction("removeFlag");
            if (ids) {
                setShowSaveModal(true);
                setActionData(ids)
            }
        }
    };

    const handleBulkUnflag = async (data) => {
        try {
            setIsLoading(true);
            await unFlagSkillForBulk(data);
            refreshDatatable();
        } catch (error) {
            throw new Error(error)
        }
    }

    const handlePageChange = (currentPage, itemsPerPage) => {
        setIsLoading(true);
        setFilter(prevFilter => {
            const updatedFilter = prevFilter
                .filter(f => f.name !== "page" && f.name !== "limit")
                .concat(
                    { name: "page", value: currentPage },
                    { name: "limit", value: itemsPerPage }
                );
            return updatedFilter;
        });
        setCurrentPage(currentPage);
        setGoToPage(0);
    };

    const handleInActiveActions = (type) => {
        if (type === "reactivate") {
            setIsBlur(true);
            const action = inActivateOptions.find(action => action.name === "reactivate");
            if (action) {
                const data = [{
                    title: action.data.title,
                    skillId: action.data._id,
                    parent: action.data.parent,
                    parentId: action.data.parentId,
                }];
                setActionData(data);
                setShowReactiveModel(true);
            }
        }
    };

    const actions = [{
        Label: "Flag",
        name: "flag",
        clickAction: () => handleActions("flagInappropriate"),
        access: flaggingAccess[0]?.add,
    }, {
        Label: "Remove Flag",
        name: "removeFlag",
        clickAction: handleRemoveFlag,
        access: flaggingAccess[0]?.delete,
    }, {
        Label: "Comments",
        name: "comments",
        clickAction: () => handleActions("comments"),
        access: commentsAccess[0]?.view,
    }, {
        Label: "publish/unpublish",
        name: "publishStatus",
        clickAction: handlePublishStatus,
        access: publishAccess[0] || unpublishAccess[0],
        publishAccess: publishAccess[0],
        unPublishAccess: unpublishAccess[0]
    }, {
        Label: "Import Questions",
        name: "importQuestions",
        clickAction: () => handleActions("importQuestions"),
        access: addQuestionAccess[0]?.add,
    }, {
        Label: "Add Video Explanation",
        name: "addViewVideoExplanation",
        clickAction: () => handleActions("addViewVideoExplanation"),
        access: skillsAccess[0]?.edit,
    }, {
        Label: "Add Question",
        name: "addQuestion",
        clickAction: handleAddQuestion,
        access: addQuestionAccess[0]?.add,
    }, {
        Label: "Edit",
        name: "edit",
        clickAction: () => handleActions("edit"),
        access: skillsAccess[0]?.edit,
    }, {
        Label: "Delete",
        name: "delete",
        class: "text-delete",
        clickAction: () => handleActions("delete"),
        access: skillsAccess[0]?.delete,
    },];

    const BulkActions = [{
        Label: "Publish All",
        name: "publish",
        clickAction: () => handleBulkActions("publish"),
        access: publishAccess[0],
    }, {
        Label: "Unpublish All",
        name: "unpublish",
        clickAction: () => handleBulkActions("unpublish"),
        access: unpublishAccess[0],
    },
    {
        Label: "Flag All",
        name: "flag",
        clickAction: () => handleBulkActions("flag"),
        access: flaggingAccess[0]?.add,
    }, {
        Label: "Unflag All",
        name: "removeFlag",
        clickAction: (() => handleBulkActions("removeFlag")),
        access: flaggingAccess[0]?.delete,
    }, {
        Label: "Delete All",
        name: "delete",
        class: "text-delete",
        clickAction: () => handleBulkActions("delete"),
        access: skillsAccess[0]?.delete,
    },];

    const inActivateOptions = [{
        Label: "Reactivate",
        name: "reactivate",
        clickAction: () => handleInActiveActions("reactivate"),
        access: publishAccess[0],
    },];

    const refreshDatatable = _.debounce(() => {
        setIsLoading(true)
        if (filter.search !== "") {
            const updatedFilter = [
                ...filter.filter(f => f.name !== "active"),
                { name: "active", value: dataFilterStatus }
            ];
            if (id) {
                const topicFilter = updatedFilter.find((f) => f.name === "topicIds")
                if (topicFilter) {
                    const data = id.split()
                    topicFilter.value = data
                }
            }
            getAllSkills(updatedFilter).then((data) => {
                setTableData(data.skills.map(extractFields));
                setTotalPageCount(data.totalPages);
                setIsBlur(false);
                setIsLoading(false);
                setIsBlur(false);
                if (data.skills && data.skills.length >= 0) {
                    setDataFetched(true);
                }
                if (id && filterKey) {
                    navigate(`/curriculum/skills/${currentPage}/${id}/${filterKey}`);
                } else {
                    navigate(`/curriculum/skills/${currentPage}`);
                }
            });
        }
        setIsBlur(false);
    }, 1000);

    const extractFields = (obj) => {
        const filterKey = "skill"
        const handleClick = async () => {
            window.open(`/curriculum/questions/${currentPage}/${obj._id}/${filterKey}`, '_blank');
        };
        const subRecordLink = (
            <span
                onClick={handleClick}
                className="record-count-link"
            >
                {obj.subRecordCount}
            </span>
        );
        const {
            code,
            isFlag,
            title,
            topic,
            subRecordCount,
            description,
            status,
            _id,
            parent,
            solution
        } = obj
        return {
            code,
            isFlag,
            title,
            topic,
            subRecordLink,
            description,
            status,
            _id,
            parent,
            solution
        };
    };

    const handleFlagComment = async () => {
        try {
            const isBulk = Array.isArray(actionData?.skillIds);
            const data = isBulk
                ? { ids: actionData.skillIds, text: flagComment, } : { id: actionData._id, text: flagComment, };
            isBulk ? await addFlagForBulkForSkill(data, true) : await addFlag(data, true);
            setIsLoading(true);
            refreshDatatable();
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    };

    const handleDeleteAction = async (e) => {
        const data = { skillIds: actionData.skillIds ?? actionData[0].skillIds, };
        try {
            await deleteSkill(data);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        } finally {
            setShowDeleteModel(false);
        }
    };

    const handleBulkPublishUnpublish = async (publish, data) => {
        try {
            await updateBulkStatus(publish, data);
            setStatus(prevStatus => !prevStatus);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleReactivate = async () => {
        try {
            const data = {
                skillId: actionData[0].skillId,
                parentId: actionData[0].parentId,
            };
            await reactivateSkill(data);
            setShowReactiveModel(false);
            refreshDatatable();
        } catch (error) {
            throw new Error("Error deleting program:", error);
        }
    };

    const handleCloseModal = (closeModal) => {
        setIsBlur(false);
        closeModal(false);
    }

    const handleMoreFilter = () => {
        setIsBlur(true);
        setShowMoreFilters(true)
    }

    return (
        <>
            <Title titleContent="Skills" />
            <div className={`curriculum-all-topics ${isBlur ? 'blur' : ''}`}>
                {dataFetched ? (
                    <>
                        <TempFilterHeader
                            filter={filter}
                            pageName="Skills"
                            setFilter={setFilter}
                            setIsBlur={setIsBlur}
                            showMoreFilters={true}
                            setIsLoading={setIsLoading}
                            handleMoreFilter={handleMoreFilter}
                            dataFilterStatus={dataFilterStatus}
                            setShowExportModal={setShowExportModal}
                            moreFiltersDropdown={moreFiltersDropdown}
                            setDataFilterStatus={setDataFilterStatus}
                            filtersDropdown={filtersDropdownStructure}
                            setGoToPage={setGoToPage}
                            isPageChange={goToPage}
                            isHavingExportAccess={isHavingExportAccess}
                        />
                        <DataTable
                            columns={columns}
                            data={tableData}
                            actions={actions}
                            totalCount={totalPageCount}
                            BulkActionOptions={BulkActions}
                            filter={filter}
                            setFilter={setFilter}
                            dataStatus={true}
                            handlePageChange={handlePageChange}
                            inActivateOptions={inActivateOptions}
                            setGoToPage={setGoToPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        {showMoreFilters && (
                            <MoreFilters
                                filter={filter}
                                pageName={"Skills"}
                                setFilter={setFilter}
                                show={showMoreFilters}
                                dataFilterStatus={dataFilterStatus}
                                moreFiltersDropdown={moreFiltersDropdown}
                                handleClose={() => handleCloseModal(setShowMoreFilters)}
                            />
                        )}
                        {showDeleteModel && (
                            <DeleteModal
                                showModal={showDeleteModel}
                                role={deleteRole}
                                onClose={() => handleCloseModal(setShowDeleteModel)}
                                onSave={refreshDatatable}
                                deleteData={actionData}
                                onClick={handleDeleteAction}
                            />
                        )}
                        {showEditModel && (
                            <EditSkillModal
                                show={showEditModel}
                                handleClose={() => handleCloseModal(setShowEditModel)}
                                initialData={actionData}
                                handleEditSkill={refreshDatatable}
                            />
                        )}
                        {showFlagInAppropriate && (
                            <FlagInappropriateModal
                                showModal={showFlagInAppropriate}
                                onClose={() => handleCloseModal(setShowFlagInAppropriate)}
                                onSave={handleFlagComment}
                                flagComment={flagComment}
                                setFlagComment={setFlagComment}
                                flagText="skill"
                            />
                        )}
                        {showImportQuestionsModel && (
                            <ImportExportQuestions
                                showModal={showImportQuestionsModel}
                                onClose={() => handleCloseModal(setShowImportQuestionsModel)}
                                onSave={refreshDatatable}
                                skillName={actionData}
                                setUploadError={setUploadError}
                                setShowBulkUploadError={setShowBulkUploadError}
                            />
                        )}
                        {showCommentsModel && (
                            <SkillCommentsModal
                                show={showCommentsModel}
                                handleClose={() => handleCloseModal(setShowCommentsModel)}
                                onSave={refreshDatatable}
                                skillId={actionData._id}
                                access={commentsAccess[0]?.add}
                            />
                        )}
                        {showReactiveModel && (
                            <ReactivateModal
                                showModal={showReactiveModel}
                                role={"Skill"}
                                onClose={() => handleCloseModal(setShowReactiveModel)}
                                onSave={() => setShowReactiveModel(false)}
                                reactivateData={actionData}
                                onClick={handleReactivate}
                            />
                        )}
                        {showUploadError && (
                            <QuestionsBulkUploadErrorModal
                                setIsBlur={setIsBlur}
                                showModal={showUploadError}
                                onClose={() => handleCloseModal(setShowBulkUploadError)}
                                error={uploadError}
                            />
                        )}
                        {showSaveModal && (
                            <SaveModal
                                showModal={showSaveModal}
                                onClose={() => setShowSaveModal(false)}
                                onSave={() => handleBulkUnflag(actionData)}
                                setModalContent={true}
                            />
                        )}
                        {showAddVideoExplanationModel && (
                            <AddViewVideoExplanation
                                showModal={showAddVideoExplanationModel}
                                onClose={() => handleCloseModal(setShowAddVideoExplanationModel)}
                                onSave={refreshDatatable}
                                skillId={actionData._id}
                                videoUrl={actionData.solution}
                            />
                        )}
                    </>
                ) : (
                    !isLoading && (
                        <div className="empty-Container">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{ display: "block", margin: "0 auto" }}
                            />
                            <b><p className="text-center" style={{ color: "green" }}>
                                You haven't created any skills yet.!</p></b>
                        </div>
                    )
                )}
            </div>
            {isLoading && (
                <div className="spinner-overlay">
                    <Loader />
                </div>
            )}
            {showExportModal && (
                <ExportModal
                    title="Skills"
                    filter={filter}
                    exportColumns={exportColumns}
                    handleClose={() => handleCloseModal(setShowExportModal)}
                />
            )}
        </>
    );
}
export default CurriculumAllSkills;