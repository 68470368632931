import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import _ from "lodash";
import DbConfig from "Constants/db";
import _debounce from "lodash/debounce";
import Title from "../../../Components/Title/Title";
import Loader from "../../../Components/Loader/Loader";
import logo from "../../../Assests/Images/empty-box.svg";
import SaveModal from "Components/Modals/SaveModal/SaveModal";
import MoreFilters from "Components/FilterHeader/MoreFilters";
import DataTable from "../../../Components/DataTable/DataTable";
import TempFilterHeader from "Components/FilterHeader/OptimizedFilterHeader";
import AddTopicModal from "../../../Components/Modals/Domain/AddTopicModal";
import DeleteModal from "../../../Components/Modals/DeleteModal/DeleteModal";
import EditDomainModal from "../../../Components/Modals/Domain/EditDomainModal";
import CommentsModal from "../../../Components/Modals/Comments/DomainsCommentsModal";
import ReactivateModal from "../../../Components/Modals/ReactivateModal/ReactivateModal";
import FlagInappropriateModal from "../../../Components/Modals/FlagInappropriateModal/FlagInappropriateModal";
import {
    addFlag,
    removeFlag,
    deleteDomain,
    getAllDomains,
    publishDomains,
    unPublishDomains,
    reactivateDomain,
    removeFlagForBulk,
    updateBulkDomainStatus,
    addFlagForBulkForDomain,
} from "../../../Utils/ApiHandler/DomainApi";
import ExportModal from "Components/Modals/ExportModal/ExportModal";

const columns = ["Code", "", "Domain Name", "Level", "Topics", "Description"];

const STATUS = {
    UNPUBLISH: -1,
    IN_REVIEW: 0,
    PUBLISH: 1,
    NO_IMAGE: 2,
};

const moreFiltersDropdown = [{
    "organization": true,
    "code": true,
    "program": false,
    "level": false,
    "domain": false,
    "topic": false,
    "skill": false,
    "question": false,
    "flag": false,
    "status": false,
    "flaggedBy": false,
}];

const filtersDropdownStructure = [{
    "program": true,
    "level": true,
    "domain": true,
    "status": true,
    "flag": true,
    "code": false,
    "skill": false,
    "topic": false,
    "question": false,
    "flaggedBy": false,
    "complexity": false,
    "questionType": false,
    "organization": false,
}];

const exportColumns = [
    { key: "domainCode", label: "Domain Code" },
    { key: "domainName", label: "Domain Name" },
    { key: "programName", label: "Program Name" },
    { key: "levelName", label: "Level Name" },
    { key: "numTopics", label: "Number of Topics" },
    { key: "numSkills", label: "Number of Skills" },
    { key: "numQuestions", label: "Number of Questions" },
    { key: "status", label: "Status" },
];

function CurriculumAllDomains({ }) {

    const { page, id, filterKey } = useParams();
    const navigate = useNavigate();

    const pageLoaded = useRef();

    const [isBlur, setIsBlur] = useState(false);
    const [status, setStatus] = useState(false);
    const [domainId, setDomainId] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [addTopic, showAddTopic] = useState(false);
    const [actionData, setActionData] = useState("");
    const [deleteRole, setDeleteRole] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [flagComment, setFlagComment] = useState("");
    const [editDomain, showEditDomain] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [deleteModel, showDeleteModel] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [reactivateRole, setReactivateRole] = useState("");
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [publishAccess, setPublishAccess] = useState(false);
    const [flaggingAccess, setFlaggingAccess] = useState(false);
    const [commentsAccess, setCommentsAccess] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [unpublishAccess, setUnpublishAccess] = useState(false);
    const [addTopicsAccess, setAddTopicsAccess] = useState(false);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [reactivateModel, showReactivateModel] = useState(false);
    const [displayComments, showDisplayComments] = useState(false);
    const [dataFilterStatus, setDataFilterStatus] = useState(true);
    const [showFlagInAppropriate, setShowFlagInAppropriate] = useState(false);
    const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
    const [goToPage, setGoToPage] = useState(0);
    const [filter, setFilter] = useState([
        { name: "page", value: 1 },
        { name: "limit", value: 10 },
        { name: "active", value: dataFilterStatus },
        { name: "status", value: "" },
        { name: "search", value: "" },
        { name: "title", value: [] },
        { name: "levelIds", value: [] },
    ]);
    const [isHavingExportAccess, setIsHavingExportAccess] = useState(false);

    useEffect(() => {
        if (!pageLoaded.current) {
            allowedAccess();
            pageLoaded.current = true;
        }
    }, []);

    useEffect(() => {
        if (goToPage > 1) {
            setCurrentPage(goToPage);
        }
        else {
            setCurrentPage(1);
        }
        navigate(`/curriculum/domain/${currentPage}`);
    }, [filter, goToPage]);

    useEffect(() => {
        refreshDatatable();
    }, [filter, status]);

    const allowedAccess = () => {
        DbConfig.curriculumAccess.toArray().then((curriculumAccessData) => {
            setFlaggingAccess(
                curriculumAccessData.map((item) => item.flagging)
            );
            setCommentsAccess(
                curriculumAccessData.map((item) => item.comments)
            );
            setPublishAccess(
                curriculumAccessData.map((item) => item.publishCurriculum)
            );
            setUnpublishAccess(
                curriculumAccessData.map((item) => item.unPublishCurriculum)
            );
            setAddTopicsAccess(curriculumAccessData.map((item) => item.topics));
            setIsHavingExportAccess(curriculumAccessData[0].exportCurriculum);

        });
    };

    const handleAction = (actionName, data) => {
        setIsBlur(true);
        showCorrespondingModal(actionName);
        const action = actions.find((action) => action.name === actionName);
        if (action && action.data !== data) setActionData(action.data);
    };

    const showCorrespondingModal = (actionName) => {
        if (actionName === "addTopic") showAddTopic(true);
        if (actionName === "editdomain") showEditDomain(true);
    };

    const handleDelete = () => {
        setIsBlur(true);
        showDeleteModel(true);
        const deleteAction = actions.find((action) => action.name === "delete");
        if (deleteAction) {
            const data = [{
                title: deleteAction.data.domain,
                domainIds: [deleteAction.data._id],
                child: deleteAction.data.topics,
            }];
            setActionData((prevData) => {
                const isSameData = prevData[0]?.domainIds[0] === data[0].domainIds[0];
                return isSameData ? prevData : data;
            });
        }
        setDeleteRole("Domain");
    };

    const handleDeleteAction = async () => {
        try {
            const domainIds = actionData?.domainIds || (Array.isArray(actionData) && actionData[0]?.domainIds);
            if (!domainIds || domainIds.length === 0) throw new Error("Invalid domainIds");
            await deleteDomain({ domainIds });
            showDeleteModel(false);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handlePublishStatus = async () => {
        setIsBlur(true);
        const publishAction = actions.find((action) => action.name === "publishStatus");
        if (publishAction) {
            try {
                const isCurrentlyPublished = publishAction.data.status === STATUS.PUBLISH;
                const newStatus = isCurrentlyPublished ? STATUS.UNPUBLISH : STATUS.PUBLISH;
                const data = { domainIds: [publishAction.data._id], status: newStatus, };
                if (isCurrentlyPublished) await unPublishDomains(data);
                else await publishDomains(data);
                setStatus((prevStatus) => !prevStatus);
                setIsLoading(true);
                // refreshDatatable();
            } catch (error) {
                throw new Error(error);
            }
        }
    };

    const handleDisplayComments = () => {
        setIsBlur(true);
        showDisplayComments(true);
        const displayAction = actions.find((action) => action.name === "displayComments");
        if (displayAction) {
            setActionData(displayAction.data.domain);
            setDomainId(displayAction.data._id);
        }
    };

    const handleFlagAction = async () => {
        setIsBlur(true);
        const flagAction = actions.find((action) => action.name === "flag");
        if (flagAction) {
            setShowFlagInAppropriate(true);
            setActionData(flagAction.data);
        }
    };

    const handleRemoveFlag = async () => {
        const removeAction = actions.find((action) => action.name === "removeFlag");
        if (removeAction) {
            try {
                const response = await removeFlag(removeAction.data._id);
                if (response) {
                    setIsLoading(true);
                    refreshDatatable();
                }
            } catch (error) {
                throw new Error(error);
            }
        }
    };

    const handleBulkActions = (actionType) => {
        const actionMapping = {
            publish: true,
            unpublish: false,
            delete: null,
        };
        const domainIds = {
            domainIds: BulkActions.filter((action) => action.name === actionType)
                .flatMap((action) => action.data.map((eachAction) => eachAction.id)),
        };
        const ids = {
            ids: BulkActions.filter((action) => action.name === actionType)
                .flatMap((action) => action.data.map((eachAction) => eachAction.id)),
        };
        if (domainIds.domainIds.length > 0) {
            setActionData(domainIds);
            if (actionType === "publish" || actionType === "unpublish") {
                handleBulkPublishUnpublish(actionMapping[actionType], domainIds);
            }
            if (actionType === "delete") {
                setIsBlur(true);
                showDeleteModel(true);
                setDeleteRole("MultipleDomains");
            }
            if (actionType === "flag") {
                setIsBlur(true);
                setShowFlagInAppropriate(true);
                setDeleteRole("domain");
            }
            if (actionType === "removeFlag") {
                setShowSaveModal(true);
                setActionData(ids);
            }
        }
    };

    const handleBulkUnflag = async (data) => {
        try {
            setIsLoading(true);
            await removeFlagForBulk(data);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleBulkPublishUnpublish = async (publish, data) => {
        try {
            await updateBulkDomainStatus(publish, data);
            setStatus((prevStatus) => !prevStatus);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handlePageChange = (currentPage, itemsPerPage) => {
        setCurrentPage(currentPage);
        setGoToPage(0);
        setIsLoading(true);
        setFilter((prevFilter) => {
            const updatedFilter = prevFilter.filter((f) => f.name !== "page" && f.name !== "limit");
            return [
                ...updatedFilter,
                { name: "page", value: currentPage },
                { name: "limit", value: itemsPerPage },
            ];
        });
    };

    const actions = [
        {
            Label: "Flag",
            name: "flag",
            clickAction: handleFlagAction,
            access: flaggingAccess[0]?.add,
        }, {
            Label: "Remove Flag",
            name: "removeFlag",
            clickAction: handleRemoveFlag,
            access: flaggingAccess[0]?.delete,
        }, {
            Label: "Comments",
            name: "displayComments",
            clickAction: handleDisplayComments,
            access: commentsAccess[0]?.view,
        }, {
            Label: "Publish/Unpublish",
            name: "publishStatus",
            clickAction: handlePublishStatus,
            access: publishAccess[0] || unpublishAccess[0],
            publishAccess: publishAccess[0],
            unPublishAccess: unpublishAccess[0],
        }, {
            Label: "Add Topic",
            name: "addTopic",
            clickAction: () => handleAction("addTopic"),
            access: addTopicsAccess[0]?.add,
        }, {
            Label: "Edit",
            name: "editdomain",
            clickAction: () => handleAction("editdomain"),
            access: addTopicsAccess[0]?.edit,
        }, {
            Label: "Delete",
            name: "delete",
            clickAction: handleDelete,
            class: "text-delete",
            access: addTopicsAccess[0]?.delete,
        },
    ];

    const BulkActions = [
        {
            Label: "Publish All",
            name: "publish",
            clickAction: () => handleBulkActions("publish"),
            access: publishAccess[0],
        }, {
            Label: "Unpublish All",
            name: "unpublish",
            clickAction: (() => handleBulkActions("unpublish")),
            access: unpublishAccess[0]
        }, {
            Label: "Flag All",
            name: "flag",
            clickAction: (() => handleBulkActions("flag")),
            access: flaggingAccess[0]?.add,
        }, {
            Label: "Unflag All",
            name: "removeFlag",
            clickAction: () => handleBulkActions("removeFlag"),
            access: flaggingAccess[0]?.delete,
        }, {
            Label: "Delete All",
            name: "delete",
            class: "text-delete",
            clickAction: (() => handleBulkActions("delete")),
            access: addTopicsAccess[0]?.delete
        },
    ];

    const handleReactivate = () => {
        const reactivateAction = inActivateOptions.find((action) => action.name === "reactivate");
        if (reactivateAction) {
            const data = [{
                title: reactivateAction.data.domain,
                domainId: reactivateAction.data._id,
            }];
            setActionData(data);
        }
        setReactivateRole("Domain");
        setIsBlur(true);
        showReactivateModel(true);
    };

    const inActivateOptions = [{
        Label: "Reactivate",
        name: "reactivate",
        clickAction: handleReactivate,
        access: publishAccess[0],
    }];

    const handleReactivateAction = async () => {
        if (actionData.length === 0) return;
        try {
            const { domainId } = actionData[0];
            await reactivateDomain({ domainId });
            showReactivateModel(false);
            setIsLoading(true);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const refreshDatatable = _.debounce(async () => {
        setIsLoading(true);
        try {
            const updatedFilter = [
                ...filter.filter(f => f.name !== "active"),
                { name: "active", value: dataFilterStatus }
            ];

            if (id) {
                const levelFilter = updatedFilter.find((f) => f.name === "levelIds")
                if (levelFilter) {
                    const data = id.split();
                    levelFilter.value = data
                }
            }
            const { domains, totalPages } = await getAllDomains(updatedFilter);
            setTableData(domains.map(extractFields));
            setTotalPageCount(totalPages);
            if (domains && domains.length >= 0) setDataFetched(true);
            id && filterKey ? navigate(`/curriculum/domain/${currentPage}/${id}/${filterKey}`) : navigate(`/curriculum/domain/${currentPage}`);
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsLoading(false);
            setIsBlur(false);
        }
    }, 1000);

    const extractFields = (obj) => {
        const filterKey = "domain"
        const handleClick = async () => {
            window.open(`/curriculum/topic/${currentPage}/${obj._id}/${filterKey}`, '_blank');
        };
        const subRecordLink = (
            <span
                onClick={handleClick}
                className="record-count-link"
            >
                {obj.subRecordCount}
            </span>
        );
        const {
            code,
            isFlag,
            title,
            level,
            subRecordCount,
            description,
            status,
            _id,
            organization,
            program,
            parent
        } = obj
        return {
            code,
            isFlag,
            title,
            level,
            subRecordLink,
            description,
            status,
            _id,
            organization,
            program,
            parent
        };
    };

    const handleMoreFilter = () => {
        setIsBlur(true);
        setShowMoreFilters(true);
    };

    const handleFlagComment = async () => {
        try {
            const isBulk = Array.isArray(actionData?.domainIds);
            const data = isBulk
                ? { ids: actionData.domainIds, text: flagComment, } : { id: actionData._id, text: flagComment, };
            setIsLoading(true);
            if (isBulk) await addFlagForBulkForDomain(data, true);
            else await addFlag(data, true);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const closeModal = (setModal) => {
        setModal(false);
        setIsBlur(false);
    };

    return (
        <>
            <Title titleContent="Domains" />
            <div className={`curriculum-all-topics ${isBlur ? "blur" : ""}`}>
                {dataFetched ? (
                    <>
                        <TempFilterHeader
                            filter={filter}
                            setFilter={setFilter}
                            pageName={"Domains"}
                            setIsBlur={setIsBlur}
                            showMoreFilters={true}
                            setIsLoading={setIsLoading}
                            handleMoreFilter={handleMoreFilter}
                            dataFilterStatus={dataFilterStatus}
                            setShowExportModal={setShowExportModal}
                            moreFiltersDropdown={moreFiltersDropdown}
                            setDataFilterStatus={setDataFilterStatus}
                            filtersDropdown={filtersDropdownStructure}
                            setGoToPage={setGoToPage}
                            isPageChange={goToPage}
                            isHavingExportAccess={isHavingExportAccess}
                        />
                        <DataTable
                            columns={columns}
                            data={tableData}
                            actions={actions}
                            totalCount={totalPageCount}
                            BulkActionOptions={BulkActions}
                            filter={filter}
                            setFilter={setFilter}
                            inActivateOptions={inActivateOptions}
                            dataStatus={true}
                            handlePageChange={handlePageChange}
                            setGoToPage={setGoToPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        {addTopic && (
                            <AddTopicModal
                                show={showAddTopic}
                                handleClose={() => closeModal(showAddTopic)}
                                parentIdentifier={actionData}
                                onSave={refreshDatatable}
                            />
                        )}
                        {editDomain && (
                            <EditDomainModal
                                show={editDomain}
                                handleClose={() => closeModal(showEditDomain)}
                                initialData={actionData}
                                onSave={refreshDatatable}
                            />
                        )}
                        {deleteModel && (
                            <DeleteModal
                                showModal={deleteModel}
                                role={deleteRole}
                                onClick={handleDeleteAction}
                                onClose={() => closeModal(showDeleteModel)}
                                onSave={refreshDatatable}
                                deleteData={actionData}
                            />
                        )}
                        {reactivateModel && (
                            <ReactivateModal
                                showModal={reactivateModel}
                                role={reactivateRole}
                                onClose={() => closeModal(showReactivateModel)}
                                reactivateData={actionData}
                                onClick={handleReactivateAction}
                            />
                        )}
                        {displayComments && (
                            <CommentsModal
                                show={displayComments}
                                handleClose={() =>
                                    closeModal(showDisplayComments)
                                }
                                initialData={actionData}
                                domainId={domainId}
                                onSave={refreshDatatable}
                                access={commentsAccess[0]?.add}
                            />
                        )}
                        {showMoreFilters && (
                            <MoreFilters
                                filter={filter}
                                pageName={"Domains"}
                                setFilter={setFilter}
                                show={showMoreFilters}
                                dataFilterStatus={dataFilterStatus}
                                moreFiltersDropdown={moreFiltersDropdown}
                                handleClose={() => closeModal(setShowMoreFilters)}
                            />
                        )}
                        {showFlagInAppropriate && (
                            <FlagInappropriateModal
                                showModal={showFlagInAppropriate}
                                onClose={() =>
                                    closeModal(setShowFlagInAppropriate)
                                }
                                onSave={handleFlagComment}
                                flagComment={flagComment}
                                setFlagComment={setFlagComment}
                                flagText="domain" />

                        )}
                        {showSaveModal && (
                            <SaveModal
                                showModal={showSaveModal}
                                onClose={() => setShowSaveModal(false)}
                                onSave={() => handleBulkUnflag(actionData)}
                                setModalContent={true}
                            />
                        )}
                    </>
                ) : (
                    !isLoading && (
                        <div className="empty-Container">
                            <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />
                            <b><p className="text-center" style={{ color: "green" }}>
                                You haven't created any domains yet.!
                            </p></b>
                        </div>
                    )
                )}
            </div>
            {isLoading && (
                <div className="spinner-overlay">
                    <Loader />
                </div>
            )}
            {showExportModal && (
                <ExportModal
                    title="Domains"
                    filter={filter}
                    exportColumns={exportColumns}
                    handleClose={() => closeModal(setShowExportModal)}
                />
            )}
        </>
    );
}
export default CurriculumAllDomains;