import React, { useState, useEffect } from "react";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import {
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageToolbar,
    ImageCaption,
    ImageResize,
    List,
    AutoImage,
    ImageUpload,
    Font,
    Heading,
    HorizontalLine,
    SelectAll,
    MediaEmbed,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    ImageInsert,
    ImageStyle,
    Alignment,
    SpecialCharacters,
    SpecialCharactersEssentials,
    ContextWatchdog,
    ClassicEditor,
    ImageInsertUI,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { fetchToken } from "Constants/ApiUrls";

const CKEditorComponent = ({
    answerField,
    onExplanationChange,
    isAnswerField,
    isQuestionText,
    disabled
}) => {
    const [errorMessageForEditor, setErrorMessageForEditor] = useState("");
    const maxCharacters = 5000;
    const [answerExplanation, setAnswerExplanation] = useState(answerField);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        if (isAnswerField) {
            setAnswerExplanation("");
        }
    }, [isAnswerField]);

    useEffect(() => {
        setAnswerExplanation(answerField);
    }, [answerField]);

    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
            this.token = fetchToken();
        }

        upload() {
            return this.loader.file.then(
                (file) =>
                    new Promise((resolve, reject) => {
                        this._initRequest();
                        this._initListeners(resolve, reject, file);
                        this._sendRequest(file);
                    })
            );
        }

        abort() {
            if (this.xhr) {
                this.xhr.abort();
            }
        }

        _initRequest() {
            const xhr = (this.xhr = new XMLHttpRequest());

            xhr.open("POST", `${baseUrl}/cms/v1/uploadExplanationImage`, true);
            xhr.responseType = "json";
            xhr.setRequestHeader("Authorization", `Bearer ${this.token}`);
        }

        _initListeners(resolve, reject, file) {
            const xhr = this.xhr;
            const loader = this.loader;
            const genericErrorText = `Couldn't upload file: ${file.name}.`;

            xhr.addEventListener("error", () => reject(genericErrorText));
            xhr.addEventListener("abort", () => reject());
            xhr.addEventListener("load", () => {
                const response = xhr.response;
                if (!response || response.error) {
                    return reject(
                        response && response.error
                            ? response.error.message
                            : genericErrorText
                    );
                }

                resolve({
                    default: response.url,
                });
            });

            if (xhr.upload) {
                xhr.upload.addEventListener("progress", (evt) => {
                    if (evt.lengthComputable) {
                        loader.uploadTotal = evt.total;
                        loader.uploaded = evt.loaded;
                    }
                });
            }
        }

        _sendRequest(file) {
            const data = new FormData();
            data.append("upload", file);
            this.xhr.send(data);
        }
    }

    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    const ckeditorConfig = (
        minCharacters,
        setErrorMessageForEditor,
        isAnswerField
    ) => {
        return {
            plugins: [
                Essentials,
                Bold,
                Italic,
                Paragraph,
                Image,
                ImageToolbar,
                ImageCaption,
                ImageResize,
                List,
                AutoImage,
                ImageUpload,
                MyCustomUploadAdapterPlugin,
                Font,
                Heading,
                HorizontalLine,
                SelectAll,
                MediaEmbed,
                Underline,
                Strikethrough,
                Subscript,
                Superscript,
                ImageInsertUI,
                Alignment,
                SpecialCharacters,
                SpecialCharactersEssentials,
                ImageStyle,
            ],
            toolbar: [
                "undo",
                "redo",
                "|",
                "bold",
                "italic",
                "|",
                "numberedList",
                "bulletedList",
                "|",
                "insertImage",
                "fontSize",
                "fontFamily",
                "fontColor",
                "fontBackgroundColor",
                "heading",
                "horizontalLine",
                "underline",
                "strikethrough",
                "subscript",
                "superscript",
                "alignment",
                "specialCharacters",
                "mediaEmbed",
            ],
            image: {
                toolbar: [
                    "imageStyle:alignLeft",
                    "imageStyle:full",
                    "imageStyle:alignRight",
                    "imageStyle:block",
                    "|",
                    "imageTextAlternative",
                ],
                styles: ["full", "alignLeft", "alignCenter", "alignRight"],
            },
            fontFamily: {
                options: [
                    "Georgia",
                    "Garamond",
                    "Ubuntu, Arial, sans-serif",
                    "Ubuntu Mono, Courier New, Courier, monospace",
                    "Fira Code",
                    "Raleway",
                    "Copperplate",
                    "Times New Roman",
                    "Papyrus",
                    "Oswald",
                    "Lobster",
                    "Georgia",
                    "Verdana",
                    "Brush Script MT",
                    "Lucida Handwriting",
                    "Anton",
                    "default",
                ],
            },
            placeholder: `Please explain the answer...`,

        };
    };

    const ckeditorConfigForQuestion = () => {
        return {
            plugins: [
                Essentials,
                Bold,
                Italic,
                Paragraph,
                Font,
                Heading,
                Underline,
                Strikethrough,
            ],
            toolbar: [
                "undo",
                "redo",
                "|",
                "bold",
                "italic",
                "fontSize",
                "fontColor",
                "fontBackgroundColor",
                "heading",
                "underline",
            ],
            placeholder: `Type Question...`,
        };
    };

    const handleAnswerExplanationChange = (event, editor) => {
        var data = editor.getData();
        const parser = new DOMParser();
        const document = parser.parseFromString(data,'text/html');
        const content = document.body.textContent || "";
        const characterCount = content.trim().length
        if (characterCount > maxCharacters) {
            setErrorMessageForEditor(
                `Maximum character limit exceeded.`
            );
            editor.enableReadOnlyMode("characterLimitExceeded");
        } else {
            setErrorMessageForEditor(
                `The current count of characters is ${characterCount} and the maximum character limit is ${maxCharacters}.`
            );
            setAnswerExplanation(data);
            onExplanationChange(data);
            if (editor.isReadOnly) {
                editor.disableReadOnlyMode("characterLimitExceeded");
            }
        }
    };
    

    const config = isQuestionText
        ? ckeditorConfigForQuestion(
            maxCharacters,
            setErrorMessageForEditor,
            isAnswerField
        )
        : ckeditorConfig(maxCharacters, setErrorMessageForEditor, isAnswerField);

    return (
        <div>
            <CKEditorContext contextWatchdog={ContextWatchdog}>
                <CKEditor
                    editor={ClassicEditor}
                    data={answerExplanation}
                    disabled={disabled} 
                    config={config}
                    onReady={(editor) => {
                        if (disabled) {
                          editor.isReadOnly = true;
                        }
                      }}
                    onChange={handleAnswerExplanationChange}
                />
            </CKEditorContext>
            {errorMessageForEditor && (
                <div className="character-validationMessge">{errorMessageForEditor}</div>
            )}
        </div>
    );
};

export default CKEditorComponent;
