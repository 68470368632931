import React, { useState, useEffect, useRef } from 'react';
import questionPreviewConfig from './questionPreviewConfig';
import speaker from "../../../../Assests/Images/speaker.svg";
import { parseLatex, parseLatexText } from 'Utils/LatexUtils';
import activeSpeaker from "../../../../Assests/Images/active-speaker.svg";
import parse from 'html-react-parser';

/**
 * PreviewOptionBuilder component for rendering question options based on question type.
 * 
 * @param {Object} questionType - The type and details of the question.
 * 
*/
const PreviewOptionBuilder = (questionType) => {
    const inputRefs = useRef({});
    
    
    const optionText = questionType.optionText;
    const optionImage = questionType.optionImage;
    const questionTypes = questionType.questionType;
    const questionText = questionType.questionText;
    
    const [rate, setRate] = useState(1);
    const [pitch, setPitch] = useState(1);
    const [speakingIndex, setSpeakingIndex] = useState(null);
    const [answer, setAnswer] = useState(questionType.answer);
    const [hasLargeImage, setHasLargeImage] = useState(false);
    const [imageDimensions, setImageDimensions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const childQuestionCount = questionType.childQuestionCount;
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
    const setChildQuestionCurrent = questionType.setChildQuestionCurrent;
    const [questionSpeakingIndex, setQuestionSpeakingIndex] = useState(null);
    
    const speechSynthesisInstance = window.speechSynthesis;
    var answerArr = Array.isArray(questionType.answer) && questionType.answer.length ? questionType.answer : [];
    var answerCount = 0;
    var voice = speechSynthesis.getVoices()[3];

    /**
     * Set speechSynthesis on component mount.
    */
    // useEffect(() => {
    //     setSpeechSynthesis(window.speechSynthesis);
    // }, []);

    /**
     * Preselect options based on correctOptions.
    */
    useEffect(() => {
        const preselectedOptions = questionType.correctOptions
            .map((isCorrect, index) => isCorrect ? index : null)
            .filter(index => index !== null);
        setSelectedOptions(preselectedOptions);
    }, [questionType.correctOptions]);

    /**
     * Load image dimensions on component mount or optionImage change.
    */
    useEffect(() => {
        optionImage.forEach((src, index) => {
            if (src) {
                getDimensions(src, index);
            }
        });
    }, [optionImage]);

    /**
     * Handle text-to-speech for an option.
     * 
     * @param {string} option - The option text.
     * @param {number} index - The index of the option.
     * 
    */
    const handleSpeak = (option, index) => {
        if ('speechSynthesis' in window) {
            const stripHTMLTags = (text) => {
                const div = document.createElement('div');
                div.innerHTML = text;
                return div.textContent || div.innerText || '';
            };
            const cleanOption = stripHTMLTags(option).replace(/<#@blank#>/g, 'blank');
            const utterance = new SpeechSynthesisUtterance(
                questionPreviewConfig.fillInTheBlanks.includes(questionType.questionType)
                    ? parseLatexText(cleanOption.replace(/_/g, 'dash'))
                    : parseLatexText(cleanOption)
            );
            utterance.voice = voice;
            utterance.pitch = pitch;
            utterance.rate = rate;

            if (speechSynthesis.speaking) {
                speechSynthesis.cancel();
                setSpeakingIndex(null);
            } else {
                setSpeakingIndex(index);
                speechSynthesis.speak(utterance);
                utterance.onend = () => setSpeakingIndex(null);
            }
        }
    };
    const handleQuestionSpeak = (option) => {
        if ('speechSynthesis' in window) {
            const stripHTMLTags = (text) => {
                const div = document.createElement('div');
                div.innerHTML = text;
                return div.textContent || div.innerText || '';
            };
            const cleanOption = stripHTMLTags(option);
            const utterance = new SpeechSynthesisUtterance(
                questionPreviewConfig.fillInTheBlanks.includes(questionType.questionType)
                    ? parseLatexText(cleanOption.replace(/_/g, 'dash'))
                    : parseLatexText(cleanOption)
            );
            utterance.voice = voice;
            utterance.pitch = pitch;
            utterance.rate = rate;

            if (speechSynthesis.speaking) {
                speechSynthesis.cancel();
                setQuestionSpeakingIndex(null);
            } else {
                setQuestionSpeakingIndex(0);
                speechSynthesis.speak(utterance);
                utterance.onend = () => setQuestionSpeakingIndex(null);
            }
        }
    };


    /**
     * Handle option click event.
     * 
     * @param {number} index - The index of the option.
     * 
    */
    const handleOptionClick = (index) => {
        handleOptionSelect(index);
    };

    /**
     * Handle answer change event.
     * 
     * @param {Object} event - The change event.
     * 
    */
    const handleChange = (event) => {
        const value = event.target.value;
        setAnswer(value);
        questionType.answer = value.split(',').map(item => item.trim());
    };

    /**
     * Get dimensions of an image.
     * 
     * @param {string} src - The source of the image.
     * @param {number} index - The index of the image.
     * 
    */
    const getDimensions = (src, index) => {
        const img = new Image();
        img.onload = () => {
            if (img.width === 400 && img.height === 400) {
                setHasLargeImage(true);
            }
            setImageDimensions((prevDimensions) => {
                const newDimensions = [...prevDimensions];
                newDimensions[index] = { width: img.width, height: img.height };
                return newDimensions;
            });
        };
        img.src = src;
    };

    /**
     * Handle option selection.
     *
     *  @param {number} index - The index of the option.
     * 
    */
    const handleOptionSelect = (index) => {
        if (questionPreviewConfig.multiSelect.includes(questionTypes)) {
            // Handle multiselect (checkbox)
            setSelectedOptions((prevSelectedOptions) => {
                if (prevSelectedOptions.includes(index)) {
                    // If the option is already selected, remove it
                    return prevSelectedOptions.filter(optionIndex => optionIndex !== index);
                } else {
                    // If the option is not selected, add it
                    return [...prevSelectedOptions, index];
                }
            });
        } else if (questionPreviewConfig.singleSelect.includes(questionTypes)) {
            // Handle singleSelect (radio)
            setSelectedOptions([index]);
        }
        setSelectedOptionIndex(index); // Update selectedOptionIndex for single select
    };

    /**
     * Capitalize "true" or "false" text.
     * 
     * @param {string} text - The text to capitalize.
     * 
     * @returns {string} - The capitalized text.
     * 
    */
    const capitalizeFirstForTrueFalse = (text) => {
        if (text) {
            const trimmedText = text.trim();
            const lowerText = trimmedText.toLowerCase();
            if (lowerText === "true" || lowerText === "false") {
                return lowerText.toUpperCase();
            }
        }
        return text;
    };

    /**
     * Handle question page change.
     *
     * @param {number} index - The index of the question page.
     * 
    */

    return (
        <div className="option-container">
            {questionType.isReadingComprehesionQuestion && (
                <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", textAlign: "justify", marginLeft: "-2rem" }}>
                    <div className="speaker-icon">
                        <img src={questionSpeakingIndex === 0 ? activeSpeaker : speaker} onClick={() => handleQuestionSpeak(questionText)} style={{ marginLeft: '4rem' }} />
                    </div>
                    <div className=' question-preview-container'>
                        <div className="text-question">
                            {questionPreviewConfig.fillInTheBlanks.includes(questionType.questionType) ? (
                                questionText && questionText.split(/(<#@blank#>)/).map((part, index) => (
                                    part === '<#@blank#>' ? (
                                        <div key={index} className="fill-in-the-blank-container">
                                            <div className="fill-in-the-blank-wrapper" style={{ marginTop: "-7px" }}>
                                                <input
                                                    type="text"
                                                    className="fill-in-the-blank"
                                                    placeholder="Enter Answer"
                                                    value={answerArr[answerCount++]}
                                                    style={{ width: '10ch' }}
                                                    maxLength={48}
                                                />
                                                <span
                                                    ref={el => inputRefs.current[index] = el}
                                                    className="fill-in-the-blank-hidden"
                                                />
                                                <div className="fill-in-the-blank-highlight"></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <span dangerouslySetInnerHTML={{ __html: parseLatex(part) }} />
                                    )
                                ))
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: parseLatex(questionText) }} />)}
                        </div>
                    </div>
                </div>
            )}
            {questionPreviewConfig.imageOption.includes(questionTypes) && (
                <div className={hasLargeImage ? "option-image-400" : "option-image"}>
                    {optionImage.map((option, index) => (
                        option !== undefined && (
                            <div key={index} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <img
                                    src={option}
                                    alt={`Image ${index + 1}`}
                                    onClick={() => handleOptionClick(index)}
                                    onLoad={questionType.onImageLoad}
                                    className={
                                        imageDimensions[index]?.width === 400 && imageDimensions[index]?.height === 400
                                            ? "image-400x400"
                                            : "image-150x150"
                                    }
                                />
                                {questionPreviewConfig.multiSelect.includes(questionTypes) ? (
                                    <input
                                        type="checkbox"
                                        className={`text-content ${selectedOptions.includes(index) ? 'selected' : ''}`}
                                        onChange={() => handleOptionSelect(index)}
                                        checked={selectedOptions.includes(index)}
                                    />) : (
                                    questionPreviewConfig.singleSelect.includes(questionTypes) && (
                                        <input
                                            type="radio"
                                            className={`text-content ${selectedOptions.includes(index) ? 'selected' : ''}`}
                                            name="singleSelectOption"
                                            onChange={() => handleOptionSelect(index)}
                                            checked={selectedOptions.includes(index)}
                                        />)
                                )}
                            </div>
                        )
                    ))}
                </div>
            )}
            {questionPreviewConfig.textOption.includes(questionTypes) && (
                <div className={`preview-options ${questionType.isReadingComprehesionQuestion ? 'RC' : ''}`}>
                    {optionText.map((option, index) => (
                        option !== undefined && option !== null && option !== "" && (
                            <div key={index} className="speaker-before-option">
                                <div className="text-and-speaker">
                                    <div className="speaker-icon" onClick={() => handleSpeak(option, index)}>
                                        <img
                                            src={speakingIndex === index ? activeSpeaker : speaker}
                                            alt="Speaker"
                                            style={{ marginTop: "1rem" }}
                                        />
                                    </div>
                                    <div className={`text-options ${selectedOptionIndex === index ? 'selected' : ''}`} onClick={() => handleOptionSelect(index)}>
                                        {questionPreviewConfig.multiSelect.includes(questionTypes) ? (
                                            <input
                                                type="checkbox"
                                                className={`text-content ${selectedOptions.includes(index) ? 'selected' : ''}`}
                                                onChange={() => handleOptionSelect(index)}
                                                checked={selectedOptions.includes(index)}
                                            />) : (
                                            questionPreviewConfig.singleSelect.includes(questionTypes) && (
                                                <input
                                                    type="radio"
                                                    className={`text-content ${selectedOptions.includes(index) ? 'selected' : ''}`}
                                                    name="singleSelectOption"
                                                    onChange={() => handleOptionSelect(index)}
                                                    checked={selectedOptions.includes(index)}
                                                />
                                            )
                                        )}
                                        <span className="text-content">
                                            <div dangerouslySetInnerHTML={{ __html: parseLatex(capitalizeFirstForTrueFalse(option)) }} style={{ marginLeft: '1rem' }} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}
            {questionPreviewConfig.textAndImageOption.includes(questionTypes) && (
                <div className={hasLargeImage ? "option-image-400" : "option-image"}>
                    {optionImage.map((option, index) => (
                        option !== undefined && option !== null && option !== "" && (
                            <div key={index}>
                                <div className={hasLargeImage ? "option-image400x400" : "option-image250x250"}>
                                    <img src={option} alt={`Image ${index + 1}`} onLoad={questionType.onImageLoad} style={{ marginBottom: '1rem' }} />
                                    <div className={hasLargeImage ? "speaker-and-text400" : "speaker-and-text"} style={{ marginBottom: '-4rem' }}>
                                        <img
                                            src={speakingIndex === index ? activeSpeaker : speaker}
                                            className="speaker-icon-before-option"
                                            onClick={() => handleSpeak(optionText[index], index)}
                                        />
                                        <div className="text-and-image-option">
                                            {questionPreviewConfig.multiSelect.includes(questionTypes) ? (
                                                <input
                                                    type="checkbox"
                                                    className={`text-content ${selectedOptions.includes(index) ? 'selected' : ''}`}
                                                    onChange={() => handleOptionSelect(index)}
                                                    checked={selectedOptions.includes(index)}
                                                />) : (
                                                questionPreviewConfig.singleSelect.includes(questionTypes) && (
                                                    <input
                                                        type="radio"
                                                        className={`text-content ${selectedOptions.includes(index) ? 'selected' : ''}`}
                                                        name="singleSelectOption"
                                                        onChange={() => handleOptionSelect(index)}
                                                        checked={selectedOptions.includes(index)}
                                                    />)
                                            )}
                                            <div dangerouslySetInnerHTML={{ __html: parseLatex(capitalizeFirstForTrueFalse(optionText[index])) }} style={{ marginLeft: '1rem' }} />
                                        </div>
                                    </div >
                                </div >
                                <div className="text-and-image">
                                    <div className="speaker-icon" onClick={() => handleSpeak(questionType.optionText[index], index)}>
                                    </div>
                                </div>
                            </div >)
                    ))}
                </div >
            )}
            {questionPreviewConfig.answerInputBox.includes(questionTypes) && (
                <div className="preview-options" style={{ marginLeft: "5.5rem" }}>
                    <input
                        type="text"
                        className="text-options"
                        placeholder="Enter answer"
                        value={questionType.answer}
                        onChange={handleChange}
                        readOnly
                    />
                </div>
            )}

        </div >
    )
}
export default PreviewOptionBuilder;
