import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../../Components/Title/Title";
import Loader from "../../../Components/Loader/Loader";
import MoreFilters from "Components/FilterHeader/MoreFilters";
import SaveModal from "Components/Modals/SaveModal/SaveModal";
import DataTable from "../../../Components/DataTable/DataTable";
import EmptyDatatable from "Components/DataTable/EmptyDatatable";
import AddSkillModal from "Components/Modals/Topics/AddSkillModal";
import DeleteModal from "Components/Modals/DeleteModal/DeleteModal";
import EditTopicModal from "Components/Modals/Topics/EditTopicsModal";
import TempFilterHeader from "Components/FilterHeader/OptimizedFilterHeader";
import ReactivateModal from "Components/Modals/ReactivateModal/ReactivateModal";
import TopicsCommentModal from "Components/Modals/Comments/TopicsCommentModal.styled";
import FlagInappropriateModal from "Components/Modals/FlagInappropriateModal/FlagInappropriateModal";
import {
    addFlag,
    deleteTopic,
    unflagTopic,
    getAllTopics,
    publishTopics,
    unPublishTopics,
    reactivateTopic,
    updateBulkStatus,
    unflagTopicForBulk,
    addFlagForBulkForTopic,
} from "Utils/ApiHandler/TopicApi";
import ExportModal from "Components/Modals/ExportModal/ExportModal";


const pageFilters = [
    { name: "page", value: 1 },
    { name: "limit", value: 10 },
    { name: "active", value: true },
    { name: "title", value: [] },
    { name: "status", value: "" },
    { name: "search", value: "" },
    { name: "domainIds", value: "" },
];

const moreFiltersDropdown = [{
    "organization": true,
    "code": true,
    "program": true,
    "level": false,
    "domain": false,
    "topic": false,
    "skill": false,
    "question": false,
    "flag": false,
    "status": false,
    "flaggedBy": false,
}];

const filtersDropdownStructure = [{
    "program": false,
    "level": true,
    "domain": true,
    "topic": true,
    "skill": false,
    "status": true,
    "flag": true,
    "code": false,
    "question": false,
    "flaggedBy": false,
    "complexity": false,
    "questionType": false,
    "organization": false,
}];

const columns = ["Code", "", "Topic Name", "Domain", "Skills", "Description"];

const STATUS = {
    UNPUBLISH: -1,
    IN_REVIEW: 0,
    PUBLISH: 1,
    NO_IMAGE: 2,
};

const exportColumns = [
    { key: "topicCode", label: "Topic Code" },
    { key: "topicName", label: "Topic Name" },
    { key: "programName", label: "Program Name" },
    { key: "levelName", label: "Level Name" },
    { key: "domainName", label: "Domain Name" },
    { key: "numSkills", label: "Number of Skills" },
    { key: "numQuestions", label: "Number of Questions" },
    { key: "status", label: "Status" },
];

function CurriculumAllTopics() {

    const { page, id, filterKey } = useParams();

    const navigate = useNavigate();

    const [topics, setTopics] = useState([]);
    const [isBlur, setIsBlur] = useState(false);
    const [status, setStatus] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [actionData, setActionData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteRole, setDeleteRole] = useState("");
    const [filter, setFilter] = useState(pageFilters);
    const [flagComment, setFlagComment] = useState("");
    const [topicsExist, setTopicsExist] = useState(false);
    const [topicsAccess, setTopicsAccess] = useState(false);
    const [showAddSkill, setShowAddSkill] = useState(false);
    const [showEditTopic, setShowEditTopic] = useState(false);
    const [publishAccess, setPublishAccess] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [flaggingAccess, setFlaggingAccess] = useState(false);
    const [commentsAccess, setCommentsAccess] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [unpublishAccess, setUnpublishAccess] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [addSkillsAccess, setAddskillsAccess] = useState(false);
    const [dataFilterStatus, setDataFilterStatus] = useState(true);
    const [showCommentsModel, setShowCommentsModel] = useState(false);
    const [showReactivateModel, setShowReactiveModel] = useState(false);
    const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
    const [showFlagInAppropriate, setShowFlagInAppropriate] = useState(false);
    const [goToPage, setGoToPage] = useState(0);
    const [isHavingExportAccess, setIsHavingExportAccess] = useState(false);

    const refreshDatatable = useCallback(
        _.debounce(() => {
            setIsLoading(true);
            const updatedFilter = [
                ...filter.filter(f => f.name !== "active"),
                { name: "active", value: dataFilterStatus }
            ];
            if (id) {
                const domainFilter = updatedFilter.find((f) => f.name === "domainIds")
                if (domainFilter) {
                    const data = id.split()
                    domainFilter.value = data
                }
            }
            getAllTopics(updatedFilter).then((data) => {
                setTopics(data.topics.map(extractFields));
                setPageCount(data.totalPages);
                setIsBlur(false);
                setIsLoading(false);
                setIsBlur(false);
                if (data.topics && data.topics.length >= 0) {
                    setTopicsExist(true);
                }
                id && filterKey ? navigate(`/curriculum/topic/${currentPage}/${id}/${filterKey}`) : navigate(`/curriculum/topic/${currentPage}`)
            });
        }, 1000),
        [filter]
    );

    useEffect(() => {
        fetchCurriculumAccess();
    }, []);

    useEffect(() => {
        refreshDatatable();
    }, [filter, status]);

    useEffect(() => {
        if (goToPage > 1) {
            setCurrentPage(goToPage);
        }
        else {
            setCurrentPage(1);
        }
        navigate(`/curriculum/topic/${currentPage}`);
    }, [filter, goToPage]);

    const BulkActions = [
        {
            Label: "Publish All",
            name: "publish",
            clickAction: (() => handleBulkActions("publish")),
            access: publishAccess[0]
        }, {
            Label: "Unpublish All",
            name: "unpublish",
            clickAction: (() => handleBulkActions("unpublish")),
            access: unpublishAccess[0]
        }, {
            Label: "Flag All",
            name: "flag",
            clickAction: (() => handleBulkActions("flag")),
            access: flaggingAccess[0]?.add,
        }, {
            Label: "Unflag All",
            name: "removeFlag",
            clickAction: (() => handleBulkActions("removeFlag")),
            access: flaggingAccess[0]?.delete,
        }, {
            Label: "Delete All",
            name: "delete",
            class: "text-delete",
            clickAction: (() => handleBulkActions("delete")),
            access: topicsAccess[0]?.delete
        }
    ];

    const inActivateOptions = [{
        Label: "Reactivate",
        name: "reactivate",
        clickAction: () => handleInActiveActions("reactivate"),
        access: publishAccess[0],
    }];

    const fetchCurriculumAccess = async () => {
        try {
            const curriculumAccessData = await DbConfig.curriculumAccess.toArray();
            const topicsAccess = [];
            const addSkillsAccess = [];
            const publishAccess = [];
            const unpublishAccess = [];
            const flaggingAccess = [];
            const commentsAccess = [];
            curriculumAccessData.forEach((item) => {
                topicsAccess.push(item.topics);
                addSkillsAccess.push(item.skills);
                publishAccess.push(item.publishCurriculum);
                unpublishAccess.push(item.unPublishCurriculum);
                flaggingAccess.push(item.flagging);
                commentsAccess.push(item.comments);
            });
            setTopicsAccess(topicsAccess);
            setAddskillsAccess(addSkillsAccess);
            setPublishAccess(publishAccess);
            setUnpublishAccess(unpublishAccess);
            setFlaggingAccess(flaggingAccess);
            setCommentsAccess(commentsAccess);
            setIsHavingExportAccess(curriculumAccessData[0].exportCurriculum);
        } catch (error) {
            throw new Error(error);
        }
    };

    const extractFields = (obj) => {
        const filterKey = "topic"
        const handleClick = async () => {
            window.open(`/curriculum/skills/${currentPage}/${obj._id}/${filterKey}`, '_blank');
        };
        const subRecordLink = (
            <span
                onClick={handleClick}
                className="record-count-link"
            >
                {obj.subRecordCount}
            </span>
        );

        const {
            code,
            isFlag,
            title,
            domain,
            subRecordCount,
            description,
            status,
            _id,
            parent
        } = obj

        return {
            code,
            isFlag,
            title,
            domain,
            subRecordLink,
            description,
            status,
            _id,
            parent
        };
    };

    const handleRemoveFlag = async () => {
        const action = actions.find((action) => action.name === "removeFlag");
        if (!action) return;
        const { _id } = action.data;
        const data = { id: _id };
        setIsLoading(true);
        try {
            const response = await unflagTopic(data);
            if (response) refreshDatatable();
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePublishStatus = async () => {
        setIsBlur(true);
        const publishAction = actions.find((action) => action.name === "publishStatus");
        if (publishAction) {
            try {
                const isCurrentlyPublished = publishAction.data.status === STATUS.PUBLISH;
                const newStatus = isCurrentlyPublished ? STATUS.UNPUBLISH : STATUS.PUBLISH;
                const data = { topicIds: [publishAction.data._id], status: newStatus, };
                if (isCurrentlyPublished) await unPublishTopics(data);
                else await publishTopics(data);
                setStatus((prevStatus) => !prevStatus);
                setIsLoading(true);
                refreshDatatable();
            } catch (error) {
                throw new Error(error);
            }
        }
    };

    const handleActions = (actionType) => {
        const actionMap = {
            flagInappropriate: () => {
                setShowFlagInAppropriate(true);
                setIsBlur(true);
                setActionData(actions.find((action) => action.name === "flag")?.data);
            },
            comments: () => {
                setShowCommentsModel(true);
                setIsBlur(true);
                setActionData(actions.find((action) => action.name === "displayComments")?.data);
            },
            addSkill: () => {
                setShowAddSkill(true);
                setIsBlur(true);
                setActionData(actions.find((action) => action.name === "addSkill")?.data);
            },
            editTopic: () => {
                setShowEditTopic(true);
                setIsBlur(true);
                setActionData(actions.find((action) => action.name === "editTopic")?.data);
            },
            delete: () => {
                setShowDeleteModel(true);
                setIsBlur(true);
                const deleteAction = actions.find((action) => action.name === "delete")?.data;
                if (deleteAction) {
                    setActionData([{
                        title: deleteAction.title,
                        topicIds: [deleteAction._id],
                        child: deleteAction.child,
                    }]);
                }
                setDeleteRole("Topic");
            },
        };

        const handleAction = actionMap[actionType];
        if (handleAction) handleAction();
    };

    const handleBulkActions = (actionType) => {
        var topicIds;
        var ids;
        const action = BulkActions.find((action) => action.name === actionType);
        if (actionType === "removeFlag") {
            ids = action.data.map((eachAction) => eachAction.id);
            setActionData({ ids });
        } else {
            topicIds = action.data.map((eachAction) => eachAction.id);
            setActionData({ topicIds });
        }
        if (actionType === "delete") {
            setDeleteRole("MultipleTopics");
            setShowDeleteModel(true);
        } else if (actionType === "flag") {
            setDeleteRole("MultipleTopics");
            setShowFlagInAppropriate(true);
        } else if (actionType === "publish" || actionType === "unpublish") {
            handleBulkPublishUnpublish(actionType === "publish", { topicIds });
        } else if (actionType === "removeFlag") {
            setShowSaveModal(true);
            setActionData({ ids })
        }
    };

    const handleBulkUnflag = async (data) => {
        try {
            setIsLoading(true);
            await unflagTopicForBulk(data);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleInActiveActions = (type) => {
        if (type === "reactivate") {
            const action = inActivateOptions.find((action) => action.name === "reactivate");
            if (action) {
                const data = [{
                    title: action.data.title,
                    parent: action.data.parent,
                    topicId: action.data._id,
                    parentId: action.data.parentId,
                }];
                setActionData(data);
                setIsBlur(true);
                setShowReactiveModel(true);
            }
        }
    };

    const handlePageChange = (currentPage, itemsPerPage) => {
        setCurrentPage(currentPage);
        setGoToPage(0);
        setIsLoading(true);
        setFilter((prevFilter) => [...prevFilter.filter(({ name }) => name !== "page" && name !== "limit"),
        { name: "page", value: currentPage },
        { name: "limit", value: itemsPerPage },
        ]);
    };

    const handleFlagComment = async () => {
        try {
            const isBulk = Array.isArray(actionData?.topicIds);
            const data = isBulk
                ? { ids: actionData.topicIds, text: flagComment, } : { id: actionData._id, text: flagComment, };
            setIsLoading(true);
            isBulk ? await addFlagForBulkForTopic(data, true) : await addFlag(data, true);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleDeleteAction = async () => {
        const topicIds = actionData.topicIds || (actionData[0] && actionData[0].topicIds);
        try {
            setIsLoading(true);
            await deleteTopic({ topicIds });
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsBlur(false);
            setShowDeleteModel(false);
            setIsLoading(false);
        }
    };

    const handleBulkPublishUnpublish = async (publish, data) => {
        try {
            updateBulkStatus(publish, data);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        } finally {
        }
    };

    const handleReactivate = async () => {
        setIsBlur(true);
        try {
            const data = { topicId: actionData[0].topicId, parentId: actionData[0].parentId, };
            await reactivateTopic(data);
            setIsBlur(false);
            setShowReactiveModel(false);
            refreshDatatable();
        } catch (error) {
            throw new Error("Error deleting program:", error);
        }
    };

    const actions = [
        {
            Label: "Flag",
            name: "flag",
            clickAction: () => handleActions("flagInappropriate"),
            access: flaggingAccess[0]?.add,
        }, {
            Label: "Remove Flag",
            name: "removeFlag",
            clickAction: handleRemoveFlag,
            access: flaggingAccess[0]?.delete,
        }, {
            Label: "Comments",
            name: "displayComments",
            clickAction: () => handleActions("comments"),
            access: commentsAccess[0]?.view,
        }, {
            Label: "Publish/Unpublish",
            name: "publishStatus",
            clickAction: handlePublishStatus,
            access: publishAccess[0] || unpublishAccess[0],
            publishAccess: publishAccess[0],
            unPublishAccess: unpublishAccess[0],
        }, {
            Label: "Add Skill",
            name: "addSkill",
            clickAction: () => handleActions("addSkill"),
            access: addSkillsAccess[0]?.add,
        }, {
            Label: "Edit",
            name: "editTopic",
            clickAction: () => handleActions("editTopic"),
            access: topicsAccess[0]?.edit,
        }, {
            Label: "Delete",
            name: "delete",
            clickAction: () => handleActions("delete"),
            class: "text-delete",
            access: topicsAccess[0]?.delete,
        },
    ];

    const handleMoreFilter = () => {
        setIsBlur(true);
        setShowMoreFilters(true);
    };

    const closeModal = (setModal) => {
        setModal(false);
        setIsBlur(false);
    };

    return (
        <>
            <Title titleContent="Topics" />
            <div className={`curriculum-all-topics ${isBlur ? "blur" : ""}`}>
                {topicsExist ? (
                    <>
                        <TempFilterHeader
                            filter={filter}
                            setFilter={setFilter}
                            showMoreFilters={true}
                            pageName="Topics"
                            setIsBlur={setIsBlur}
                            setIsLoading={setIsLoading}
                            handleMoreFilter={handleMoreFilter}
                            dataFilterStatus={dataFilterStatus}
                            setShowExportModal={setShowExportModal}
                            moreFiltersDropdown={moreFiltersDropdown}
                            setDataFilterStatus={setDataFilterStatus}
                            filtersDropdown={filtersDropdownStructure}
                            setGoToPage={setGoToPage}
                            isPageChange={goToPage}
                            isHavingExportAccess={isHavingExportAccess}
                        />
                        <DataTable
                            columns={columns}
                            data={topics}
                            actions={actions}
                            totalCount={pageCount}
                            BulkActionOptions={BulkActions}
                            filter={filter}
                            setFilter={setFilter}
                            inActivateOptions={inActivateOptions}
                            dataStatus={true}
                            handlePageChange={handlePageChange}
                            setGoToPage={setGoToPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        {showFlagInAppropriate && (
                            <FlagInappropriateModal
                                showModal={showFlagInAppropriate}
                                onClose={() => {
                                    setIsBlur(false);
                                    setShowFlagInAppropriate(false);
                                }}
                                onSave={handleFlagComment}
                                flagComment={flagComment}
                                setFlagComment={setFlagComment}
                                flagText="topic"
                            />
                        )}
                        {showCommentsModel && (
                            <TopicsCommentModal
                                show={showCommentsModel}
                                handleClose={() => {
                                    setIsBlur(false);
                                    setShowCommentsModel(false);
                                }}
                                onSave={refreshDatatable}
                                skillId={actionData._id}
                                access={commentsAccess[0]?.add}
                            />
                        )}
                        {showAddSkill && (
                            <AddSkillModal
                                show={showAddSkill}
                                handleClose={() => {
                                    setIsBlur(false);
                                    setShowAddSkill(false);
                                }}
                                handleAddSkill={refreshDatatable}
                                parentIdentifier={actionData}
                            />
                        )}
                        {showEditTopic && (
                            <EditTopicModal
                                show={showEditTopic}
                                handleClose={() => {
                                    setIsBlur(false);
                                    setShowEditTopic(false);
                                }}
                                initialData={actionData}
                                handleEditTopic={refreshDatatable}
                            />
                        )}
                        {showDeleteModel && (
                            <DeleteModal
                                showModal={showDeleteModel}
                                role={deleteRole}
                                onClose={() => {
                                    setIsBlur(false);
                                    setShowDeleteModel(false);
                                }}
                                onSave={refreshDatatable}
                                deleteData={actionData}
                                onClick={handleDeleteAction}
                            />
                        )}
                        {showReactivateModel && (
                            <ReactivateModal
                                showModal={showReactivateModel}
                                role={"Topic"}
                                onClose={() => {
                                    setIsBlur(false);
                                    setShowReactiveModel(false);
                                }}
                                onSave={() => {
                                    setIsBlur(false);
                                    setShowReactiveModel(false);
                                }}
                                reactivateData={actionData}
                                onClick={handleReactivate}
                            />
                        )}
                        {showSaveModal && (
                            <SaveModal
                                showModal={showSaveModal}
                                onClose={() => setShowSaveModal(false)}
                                onSave={() => handleBulkUnflag(actionData)}
                                setModalContent={true}
                            />
                        )}
                        {showMoreFilters && (
                            <MoreFilters
                                filter={filter}
                                pageName={"Topics"}
                                setFilter={setFilter}
                                show={showMoreFilters}
                                dataFilterStatus={dataFilterStatus}
                                moreFiltersDropdown={moreFiltersDropdown}
                                handleClose={() => closeModal(setShowMoreFilters)}
                            />
                        )}
                    </>
                ) : (
                    !isLoading && <EmptyDatatable label="Topic" />
                )}
            </div>
            {isLoading && (
                <div className="spinner-overlay">
                    <Loader />
                </div>
            )}
            {showExportModal && (
                <ExportModal
                    title="Topics"
                    filter={filter}
                    exportColumns={exportColumns}
                    handleClose={() => closeModal(setShowExportModal)}
                />
            )}
        </>
    );
}

export default CurriculumAllTopics;